import React from 'react';
import { GrFormClose } from 'react-icons/gr';
import { format } from 'date-fns';
export const allowedMethods = ['equalTo', 'containsTo'];

export function ShowFilterView({ allFilterInfo, deleteFilter, extraClass, setModalState, openModal }) {
	console.log(extraClass, 'extraClass', allFilterInfo);
	return (
		<div className={!extraClass ? 'custom-filter-container' : `custom-filter-container ${extraClass}`}>
			{allFilterInfo.map((el) => {

				return (
					<div
						onClick={(e) => {
							if (extraClass) {
								setModalState(!openModal);
							} else {
								deleteFilter(el.id)();
							}
						}}
						className="each-selected-filter"
						key={el.id}
					>
						<div>
							{el.type === 'text' ? (
								<>
									{el.label} {el.method === allowedMethods[0] ? 'is: ' : 'contains: '}
									{el.value.map(
										(eachValue, index) => eachValue + (index + 1 === el.value.length ? '. ' : ', ')
									)}
								</>
							) : null}
							{el.type === 'date' ? (
								<>
									{el.label}: {format(el.value.startDate, 'MMM dd, yyyy')} -
									{format(el.value.endDate, 'MMM dd, yyyy')}
								</>
							) : null}
							{el.type === 'radio' ? (
								<>
									{el.label}: {el.value && el.value.label}
								</>
							) : null}
							{el.type === 'dropdown' ? (
								<>
									{el.label}: {el.value.label ? el.value.label : el.value.map((eachValue, index) => eachValue.label + (index + 1 === el.value.length ? '. ' : ', '))}
									{/* {el.value.map((eachValue, index) => eachValue + (index + 1 === el.value.length ? '. ' : ', '))} */}
								</>
							) : null}
						</div>
						{extraClass ? null : <GrFormClose style={{ fontSize: '18px' }} className="close-button" />}
					</div>
				);
			})}
		</div>
	);
}
