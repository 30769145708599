import React, { useState } from 'react';
import { getApi, postApi } from '../../services/api';
import { showSnack } from 'react-redux-snackbar';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { Select, Space, Input, DatePicker, Form, Button } from 'antd';
import './index.css'
import HeaderComponent from '../../components/Header';
const { TextArea } = Input;


const CreateTicket = () => {
    const [assetsList, setAssetsList] = useState([]);
    const [empList, setEmpList] = useState([]);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const getAssetTypes = debounce(async (searchText) => {
        if (searchText.trim() === "") return null;
        try {
            const resp = await getApi(`/assets/search-assets?assetName=${searchText}`);
            if (resp.type === 'success') {
                console.log(resp?.assets);
                setAssetsList(resp?.assets);
            } else {
                dispatch(
                    showSnack('ASSET_SEARCH_ERROR', {
                        label: resp.error,
                        timeout: 7000,
                    })
                );
            }
        } catch (e) {
            dispatch(
                showSnack('ASSET_SEARCH_ERROR', {
                    label: 'Something went wrong',
                    timeout: 7000,
                })
            );
        }
    }, 1000);

    const searchEmployeeFilter = debounce(async (filterText) => {
        if (filterText.trim() === "") return null;
        try {
            if (filterText && filterText.length >= 3) {
                const resp = await postApi({ filterText }, '/users/get-employee');
                console.log(resp);
                if (resp.type === 'success') {
                    setEmpList(resp.employeeList);
                }
            } else {
                setEmpList([]);
            }
        } catch (e) { }
    }, 1000);

    const onChange = (value) => {
        console.log(`selected ${value}`);
    };

    const handleSearch = (value) => {
        getAssetTypes(value);
    };

    const empOptions = empList.map((option) => {
        return {
            label: option.name,
            value: option._id,
            email: option.email,
        };
    });

    const stockOptions = assetsList.map((option) => {
        return {
            label: option.name,
            value: option.id,
            serialNumber: option.serialNumber,
        };
    });
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const issueOptions = [
        {
            label: 'Software Issue',
            value: 'software'
        },
        {
            label: 'Hardware Issue',
            value: 'hardware'
        },
        {
            label: 'Charger Issue',
            value: 'charger'
        },
        {
            label: 'Not switching on',
            value: 'switchingOn'
        },
        {
            label: 'Don’t know the reason',
            value: 'others'
        }
    ]


    const priorityOptions = [
        {
            label: 'High',
            value: 'high'
        },
        {
            label: 'Medium',
            value: 'medium'
        },
        {
            label: 'Low',
            value: 'low'
        },
    ]

    console.log(empOptions);

    const onSubmit = (data) => {
        console.log(data);
    }


    return (
        <div className='create-ticket-div'>
            <HeaderComponent />
            <div className='heading'>Incident Management / Create Ticket</div>
            <div className='datadiv'>
                <div className='maindiv'>
                    <div className='innerdiv'>
                        <Form form={form} onFinish={onSubmit}>

                            <div className='input-label'>Select Employee</div>
                            <Form.Item
                                name="selectedEmployee"
                            >
                                <Select
                                    style={{ width: '100%', marginBottom: "20px" }}
                                    size='large'
                                    showSearch
                                    filterOption={filterOption}
                                    onSearch={(val) => { searchEmployeeFilter(val); }}
                                    placeholder='Select Employee'
                                    options={empOptions}
                                    optionRender={(option) => {
                                        return (
                                            <div key={option.value} style={{ height: '50px', display: "flex", flexDirection: "column" }}>
                                                {/* <div> */}
                                                <div>{option.data.label}</div>
                                                <div>{option.data.email}</div>
                                                {/* </div> */}
                                            </div>
                                        )
                                    }}
                                ></Select>
                            </Form.Item>

                            <div className='input-label'>Select Asset</div>
                            <Form.Item
                                name="selectedAsset"
                            >
                                <Select
                                    size='large'
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                        marginBottom: "20px"
                                    }}
                                    placeholder="Select Device"
                                    filterOption={filterOption}
                                    onChange={onChange}
                                    onSearch={handleSearch}
                                    options={stockOptions}
                                    optionRender={(option) => {
                                        const imageUrl =
                                            option.images?.length > 0
                                                ? option.images[0]
                                                : 'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg1.png';
                                        return (
                                            <Space style={{ height: '50px', display: 'flex', gap: '50px' }}>
                                                <div>
                                                    <img src={imageUrl} style={{ width: '40px', height: '40px' }} />
                                                </div>
                                                <div>
                                                    <div>{option.data.label}</div>
                                                    <div>{option.data.value}</div>
                                                </div>
                                                <div>
                                                    <div>{option.data.serialNumber}</div>
                                                    <div>8GB 256GB</div>
                                                </div>
                                            </Space>
                                        );
                                    }}
                                ></Select>
                            </Form.Item>


                            <div className='input-label'>Problem</div>
                            <Form.Item
                                name="problem"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Provide Branch ID',
                                    },
                                ]}
                            >
                                <Select style={{
                                    width: '100%', marginBottom: "20px"
                                }} size='large' placeholder='Select Issue' options={issueOptions} />
                            </Form.Item>

                            <div className='input-label'>Describe the issue</div>
                            <Form.Item
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Provide Branch ID',
                                    },
                                ]}
                            >
                                <TextArea size='large' style={{ marginBottom: "20px" }} rows={4} placeholder='Enter the issue that you faced here' />
                            </Form.Item>

                            <div className='input-label'>Issue start date</div>
                            <Form.Item
                                name="issueDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Provide Branch ID',
                                    },
                                ]}
                            >
                                <DatePicker style={{
                                    width: '100%', marginBottom: "20px"
                                }} placeholder='Select Date' size='large' />
                            </Form.Item>

                            <div className='input-label'>Priority</div>
                            <Form.Item
                                name="priority"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Provide Branch ID',
                                    },
                                ]}
                            >
                                <Select style={{
                                    width: '100%', marginBottom: "20px"
                                }} placeholder='Select Priority' size='large' options={priorityOptions} />
                            </Form.Item>

                            <div style={{ display: "flex", justifyContent: "right", gap: "20px" }}>
                                <Button size='large'>Cancel</Button>
                                <Button onClick={() => { form.submit(); }} type='primary' size='large'>Create</Button>
                            </div>
                        </Form>
                    </div>

                </div>
                <div className='img-div'>
                    <img src={require('../../images/create-ticket.png')} />
                </div>
            </div >
        </div >
    )
}

export default CreateTicket