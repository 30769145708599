export const defaultColumns = [
	{
		name: 'Date of Purchase',
		checked: false,
		mapId: 'dateOfPurchase',
		type: 'date',
	},
	{
		name: 'Date of Assign',
		checked: false,
		mapId: 'dateOfAssign',
		type: 'date',
	},
	{
		name: 'Price',
		checked: false,
		mapId: 'price',
		type: 'int',
	},
	{
		name: 'Health',
		checked: true,
		mapId: 'health',
		type: 'array',
	},
	{
		name: 'Warranty Remaining',
		checked: false,
		mapId: 'warrantyRemaining',
		type: 'date',
	},
	{
		name: 'IMEI',
		checked: false,
		mapId: 'imei',
		type: 'int',
	},
	{
		name: 'Vendor',
		checked: false,
		mapId: 'vendor',
		type: '',
	},
	{
		name: 'Branch ID',
		checked: false,
		mapId: 'officeId.branchId',
		type: '',
	},
	{
		name: 'Employee ID',
		checked: false,
		mapId: 'employee.id',
		type: '',
	},
];

export function addExtraColums(customKeys, currentColumns) {
	for (let x = 0; x < customKeys.length; x += 1) {
		const colFound = currentColumns.find((el) => el.mapId === customKeys[x].key && el.custom);
		if (!colFound) {
			currentColumns = [
				...currentColumns,
				{
					name: customKeys[x].key,
					checked: false,
					mapId: customKeys[x].key,
					type: '',
					custom: true,
				},
			];
		}
	}
	console.log(currentColumns, 'currentColumns121212');
	return currentColumns;
}
