import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getApi, postApi } from "../../services/api";
import { ApiPost } from "../../services/agentConfig";
import { showSnack } from "react-redux-snackbar";
import { differenceInDays, format } from "date-fns";
import { Button, Card, Collapse, DatePicker, Input, Popover, Select, Space, Table, Upload } from "antd";
import { ACTIVITY_VIEW, AUDIT_CREATE, AUDIT_VIEW, CheckPermission } from "../../utils/Permissions";
import { isArray } from "lodash";
import { UploadOutlined } from '@ant-design/icons';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { handleAuditFileUpload } from "./utils";
const dateFormat = 'YYYY/MM/DD';
dayjs.extend(customParseFormat);


const DeviceHistory = ({ assetId, selectedAsset, fullInfo, memoryInformation }) => {
    const dispatch = useDispatch();
    const [deviceHistory, setDeviceHistory] = useState([]);
    const [auditHealth, setAuditHealth] = useState([]);
    const [healthInfo, setCustomHealth] = useState([]);
    const [editAuditHealth, setEditAuditHealth] = useState(false);
    const [assetUpdateHistory, setAssetUpdateHistory] = useState([]);

    useEffect(() => {
        const getHistory = async function () {
            try {
                const resp = await postApi({ assetId }, '/assetze/asset-history/history-list');
                if (resp.type === 'success') {
                    setDeviceHistory(resp.assetStories);
                }
            } catch (e) {
                console.error(e);
            }
        };

        async function getAssetInfo() {
            const resp = await postApi({ assetId }, '/assets/get-asset');
            if (resp.type === 'success') {
                console.log('asset info is found');
                const assetInfo = resp.data;
                if (assetInfo.health && assetInfo.health.length) {
                    setCustomHealth(assetInfo.health);
                }
            }
        }

        // async function getAssetHistory() {
        // 	const resp = await postApi({ assetId }, '/assetze/asset-history/all-update-report');
        // 	if (resp.type === 'success') {
        // 		if (resp.assetHistory) {
        // 			setAssetUpdateHistory(resp.assetHistory);
        // 		}
        // 	}
        // }
        getHistory();
        getAssetInfo();
        // getAssetHistory();
    }, [assetId]);

    useEffect(() => {
        getAuditHealth();
    }, [editAuditHealth]);

    const getAuditHealth = async function () {
        try {
            const resp = await ApiPost(
                {
                    companyId: selectedAsset.companyId,
                    assetId: selectedAsset._id,
                    page: 1,
                    limit: 10,
                },
                '/assetze/asset-audit/get'
            );
            if (resp.type === 'success') {
                setAuditHealth(resp.data);
            } else {
                dispatch(
                    showSnack('INVALID_HEALTH', {
                        label: resp.error,
                        timeout: 7000,
                    })
                );
            }
            return null;
        } catch (e) {
            console.error(e);
        }
    };

    const HistoryTabData = deviceHistory.map((el, index) => {
        return {
            key: index,
            Employee: el.info.name,
            AssignedOn: format(new Date(el.info.assignedOn), 'dd MMM yyyy'),
            ReturnedOn: el.info.unassigned ? format(new Date(el.info.unassigned), 'dd MMM yyyy') : 'NA',
            Duration: el.info.unassigned
                ? differenceInDays(new Date(el.info.unassigned), new Date(el.info.assignedOn))
                : differenceInDays(new Date(), new Date(el.info.assignedOn)),
            TicketsRaised: '-',
        };
    });

    const HistoryTabColumns = [
        {
            title: 'Employee',
            dataIndex: 'Employee',
            key: '1',
            align: 'center',
        },
        {
            title: 'Assigned on',
            dataIndex: 'AssignedOn',
            key: '2',
            align: 'center',
        },
        {
            title: 'Returned on',
            dataIndex: 'ReturnedOn',
            key: '3',
            align: 'center',
        },
        {
            title: 'Duration',
            dataIndex: 'Duration',
            key: '4',
            align: 'center',
        },
        {
            title: 'Tickets Raised',
            dataIndex: 'TicketsRaised',
            key: '5',
            align: 'center',
        },
    ];

    const AuditHealthColumn = [
        {
            title: 'Condition',
            dataIndex: 'condition',
            key: '1',
            align: 'center',
        },
        {
            title: 'Internal parts',
            dataIndex: 'internalparts',
            key: '2',
            align: 'center',
        },
        {
            title: 'physical damage',
            dataIndex: 'physicaldamage',
            key: '3',
            align: 'center',
        },
        {
            title: 'software issue',
            dataIndex: 'softwareissue',
            key: '4',
            align: 'center',
        },
        {
            title: 'in usable condition?',
            dataIndex: 'usablecondition',
            key: '5',
            align: 'center',
        },
        {
            title: 'ready to be disposed',
            dataIndex: 'todispose',
            key: '6',
            align: 'center',
            width: '16%',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: '7',
            align: 'center',
            width: '16%',
        },
    ];

    const assetUpdateColumns = [
        {
            title: 'Employee',
            dataIndex: 'Employee',
            key: '1',
            align: 'center',
        },
        {
            title: 'Assigned on',
            dataIndex: 'AssignedOn',
            key: '2',
            align: 'center',
        },
        {
            title: 'Returned on',
            dataIndex: 'ReturnedOn',
            key: '3',
            align: 'center',
        },
        {
            title: 'Duration',
            dataIndex: 'Duration',
            key: '4',
            align: 'center',
        },
        {
            title: 'Tickets Raised',
            dataIndex: 'TicketsRaised',
            key: '5',
            align: 'center',
        },
    ];

    const AuditHealthData = auditHealth.map((data, index) => ({
        key: index,
        condition: data.health[0]?.value,
        internalparts: data.health[1]?.value,
        physicaldamage: data.health[2]?.value,
        softwareissue: data.health[3]?.value,
        usablecondition: data.health[4]?.value,
        todispose: data.health[5]?.value,
        date: format(new Date(data?.createdAt), 'dd MMM yyyy'),
    }));

    // return null;
    return (
        <>
            <div className="tab-history">
                <div className="tab-history-innerdiv">
                    <Table dataSource={HistoryTabData} columns={HistoryTabColumns} scroll={{ x: 500 }} />

                    {editAuditHealth ? (
                        <AddHealth
                            healthInfoExist={healthInfo}
                            setCustomHealth={setCustomHealth}
                            setEditAuditHealth={setEditAuditHealth}
                            selectedAsset={selectedAsset}
                            fullInfo={fullInfo}
                            memoryInformation={memoryInformation}
                        />
                    ) : (
                        <div className="audit-table">
                            <CheckPermission requiredPermission={AUDIT_CREATE}>
                                <Button
                                    type="primary"
                                    className="edit-btn"
                                    onClick={() => {
                                        setEditAuditHealth(true);
                                    }}
                                >
                                    <EditOutlined />
                                    Audit Asset
                                </Button>
                            </CheckPermission>
                            <CheckPermission requiredPermission={AUDIT_VIEW}>
                                <Table
                                    pagination={{
                                        pageSize: 5,
                                    }}
                                    dataSource={AuditHealthData}
                                    columns={AuditHealthColumn}
                                    scroll={{ x: 650 }}
                                />
                            </CheckPermission>
                        </div>
                    )}

                    <ShowAssetHistoryInfo assetId={assetId} />
                </div>
            </div>
        </>
    );
};

export default DeviceHistory

const ChangesMadeColumns = [
    { title: 'Key', dataIndex: 'type', key: '1', align: 'center' },
    {
        title: 'Previous Value',
        dataIndex: 'previousValue',
        key: '3',
        align: 'center',
        render: (el) => {
            if (el) {
                return el;
            }
            return 'NA';
        },
    },
    { title: 'New Value', dataIndex: 'currentValue', key: '3', align: 'center' },
];

const AssetActivityInfo = [
    {
        title: 'Action',
        dataIndex: 'actionType',
        key: '1',
        align: 'center',
    },
    {
        title: 'Action Time',
        dataIndex: 'createdAt',
        key: '2',
        align: 'center',
    },
    {
        title: 'Admin',
        dataIndex: 'payload',
        key: '3',
        render: (el) => {
            return <div>{el ? el.name : null}</div>;
        },
    },
    {
        title: 'Info',
        dataIndex: 'payload',
        key: '4',
        align: 'center',
        render: (payload, record) => {
            if (record.actionType === 'ASSET_UPDATED') {
                const eventsList = isArray(payload.updatedInfo.assetChanges) ? payload.updatedInfo.assetChanges : [];
                console.log(eventsList, 'eventsList');

                const rows = eventsList.map((el) => {
                    let event = {};
                    if (el.key.includes('custom') || el.key.includes('health')) {
                        event.type = el.key.replace(/\[key=(.*?)\](?:\.value)?/, '.$1');
                        event.previousValue = el.previousValue;

                        if (el.type === 'key') {
                            event.currentValue = el.currentValue;
                            //typeof el.currentValue === 'object'
                        } else if (el.type === 'array') {
                            event.currentValue = el.currentValue ? el.currentValue.value : '';
                        }
                    } else {
                        event.type = el.key;
                        event.currentValue = el.currentValue;
                        event.previousValue = el.previousValue;
                    }

                    return event;
                });

                console.log(rows, 'rows');

                const content = (
                    <Table
                        pagination={{
                            pageSize: 10,
                            total: eventsList.length,
                        }}
                        bordered={false}
                        dataSource={rows}
                        columns={ChangesMadeColumns}
                    />
                );

                return (
                    <Popover content={content} title="Asset Properties Changed:">
                        <InfoCircleOutlined style={{ color: '#32D7DB', cursor: 'pointer' }} color="#32D7DB" />
                    </Popover>
                );
            } else if (record.actionType === 'ASSET_ASSIGNED') {
                const str = `Asset Assigned to: ${payload.assignedTo.name}`;
                return (
                    <Popover content={str} title="Assign/UnAssign Operation">
                        <InfoCircleOutlined style={{ color: '#32D7DB', cursor: 'pointer' }} color="#32D7DB" />
                    </Popover>
                );
            } else if (record.actionType === 'ASSET_UNASSIGNED') {
                const str = `Asset UnAssigned from: ${payload.unassignedFrom.name}`;
                return (
                    <Popover content={str} title="Assign/UnAssign Operation">
                        <InfoCircleOutlined style={{ color: '#32D7DB', cursor: 'pointer' }} color="#32D7DB" />
                    </Popover>
                );
            }

            return null;
        },
    },
];

const ShowAssetHistoryInfo = ({ assetId }) => {
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [filterSelected, setSelectedFilter] = React.useState(['ASSET_ASSIGNED', 'ASSET_UNASSIGNED', 'ASSET_UPDATED']);
    const [rows, setRows] = React.useState([]);
    const [totalRows, setTotalRows] = React.useState(0);
    useEffect(() => {
        const getAudits = async function () {
            try {
                const resp = await getApi(
                    `/assetze/audit?page=${page}&limit=${pageSize}&filterText=${filterSelected.join(
                        ','
                    )}&assetId=${assetId}`
                );
                if (resp.type === 'success') {
                    const auditList = [];
                    for (let x = 0; x < resp.auditList.length; x++) {
                        auditList.push(resp.auditList[x]);
                    }
                    setRows(resp.auditList);
                    setTotalRows(resp.totalAuditCount);
                }
            } catch (e) { }
        };
        getAudits();
    }, [page, pageSize, filterSelected]);

    return (
        <div>
            <CheckPermission requiredPermission={ACTIVITY_VIEW}>
                <h5 style={{ marginBottom: "15px", paddingLeft: "10px" }}>Asset Activity</h5>

                <Table
                    pagination={{
                        pageSize: pageSize,
                        total: totalRows,
                        onChange: (page, pageSize) => {
                            setPage(page);
                            setPageSize(pageSize);
                        },
                    }}
                    dataSource={rows}
                    columns={AssetActivityInfo}
                    scroll={{ x: 600 }}
                />
            </CheckPermission>
        </div>
    );
};

const convertOptionInputs = function (el) {
    return { value: el, label: el.charAt(0).toUpperCase() + el.slice(1) };
};

const defaultHealth = [
    {
        key: 'Condition',
        value: '',
        comment: '',
        alias: 'H1',
        type: 'dropdown',
        inputs: ['working', 'repaired', 'dead'].map(convertOptionInputs),
    },
    {
        key: 'Internal Parts',
        value: '',
        comment: '',
        alias: 'H2',
        type: 'dropdown',
        inputs: ['present', 'damaged', 'missing'].map(convertOptionInputs),
    },
    {
        key: 'Physical Damage',
        value: '',
        comment: '',
        alias: 'H3',
        type: 'dropdown',
        inputs: ['yes', 'no'].map(convertOptionInputs),
    },
    {
        key: 'Software Issue',
        value: '',
        comment: '',
        alias: 'H4',
        type: 'dropdown',
        inputs: ['yes', 'no'].map(convertOptionInputs),
    },
    {
        key: 'Is the product ready to be disposed?',
        value: '',
        comment: '',
        alias: 'H6',
        type: 'dropdown',
        inputs: ['yes', 'no', 'already disposed'].map(convertOptionInputs),
    },
    {
        key: 'is the product still in usable condition??',
        value: '',
        comment: '',
        alias: 'H5',
        type: 'dropdown',
        inputs: ['yes', 'no'].map(convertOptionInputs),
    },
];

const AddHealth = ({ setCustomHealth, healthInfoExist, setEditAuditHealth, selectedAsset, fullInfo, memoryInformation }) => {
    // const [healthInfo, setHealthInfo] = useState(healthInfoExist.length > 0 ? healthInfoExist : defaultHealth);
    const [healthInfo, setHealthInfo] = useState(defaultHealth);
    const [auditInfo, setAuditInfo] = useState({
        preventiveDate: format(new Date(), 'yyyy/MM/dd')
    });
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);


    useEffect(() => {
        // setHealthInfo(healthInfoExist);
        setAuditInfo({
            ...auditInfo,
            userName: selectedAsset?.employee?.name,
            department: selectedAsset?.department,
            assetType: selectedAsset?.type,
            hostName: fullInfo?.systemInfo?.hostname,
            userEmpCode: selectedAsset?.employee?.employeeId,
            assetCode: selectedAsset?.id,
            ...memoryInformation,
            userEmail: selectedAsset?.employee?.email
        })
    }, [setHealthInfo, healthInfoExist]);

    const updateHealthInfo = function (selectedHealth, type, value) {
        setHealthInfo(
            healthInfo.map((eachHealth) => {
                if (eachHealth.alias !== selectedHealth.alias) {
                    return eachHealth;
                }
                if (type === 'dropdown') {
                    return {
                        ...eachHealth,
                        value,
                    };
                }
                if (type === 'comment') {
                    return {
                        ...eachHealth,
                        comment: value,
                    };
                }
            })
        );
    };

    const onhandleCancel = () => {
        setHealthInfo(defaultHealth);
        setEditAuditHealth(false);
    };
    const OnhandleSave = async function () {
        try {
            const files = await handleAuditFileUpload(fileList, selectedAsset._id)
            const resp = await postApi(
                {
                    companyId: selectedAsset.companyId,
                    assetId: selectedAsset._id,
                    health: healthInfo,
                    auditInfo,
                    files
                },
                '/assetze/asset-audit/create'
            );
            if (resp.type === 'success') {
                dispatch(
                    showSnack('INVALID_HEALTH', {
                        label: 'Audit History Submitted Successfully',
                        timeout: 7000,
                    })
                );
                setFileList([]);
                setEditAuditHealth(false);
            } else {
                dispatch(
                    showSnack('INVALID_HEALTH', {
                        label: resp.error,
                        timeout: 7000,
                    })
                );
            }
        } catch (e) {
            console.error(e);
            dispatch(
                showSnack('INVALID_HEALTH', {
                    label: e.message,
                    timeout: 7000,
                })
            );
        }
    };

    const saveHealthInfo = function () {
        //setCustomHealth
        for (let x = 0; x < healthInfo.length; x++) {
            const eachHealth = healthInfo[x];
            if (!eachHealth.value) {
                return dispatch(
                    showSnack('INVALID_HEALTH', {
                        label: 'Please fill all the health fields',
                        timeout: 7000,
                    })
                );
            }
        }
        setCustomHealth(healthInfo);
        setEditAuditHealth(false);
    };

    console.log(auditInfo);

    const items = [
        {
            key: '1',
            label: 'Asset Info',
            children:
                <div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Preventive Date</div>
                        <div className='audit-info-input'>
                            <DatePicker style={{ background: "white", width: "100%" }}
                                format={dateFormat}
                                disabled
                                placeholder={`Enter Preventive Date`}
                                value={auditInfo.preventiveDate ? dayjs(auditInfo.preventiveDate, dateFormat) : null}
                                onChange={(date, dateString) => setAuditInfo({ ...auditInfo, preventiveDate: dateString })}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">User Name</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter User Name`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, userName: e.target.value })}
                                value={auditInfo.userName}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">User Emp Code</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter User Emp Code`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, userEmpCode: e.target.value })}
                                value={auditInfo.userEmpCode}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Department</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Department`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, department: e.target.value })}
                                value={auditInfo.department}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Asset Type</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Asset Type`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, assetType: e.target.value })}
                                value={auditInfo.assetType}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Asset Code</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Asset Code`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, assetCode: e.target.value })}
                                value={auditInfo.assetCode}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Make/Modal</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Make/Modal`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, makeModal: e.target.value })}
                                value={auditInfo.makeModal}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Host Name</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Host Name`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, hostName: e.target.value })}
                                value={auditInfo.hostName}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">RAM</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter RAM`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, ram: e.target.value })}
                                value={auditInfo.ram}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Memory(HDD/SDD)</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Memory(HDD/SDD)`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, memory: e.target.value })}
                                value={auditInfo.memory}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Processor</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Processor`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, processor: e.target.value })}
                                value={auditInfo.processor}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Processor in GHz</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Processor in GHz`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, processorGHz: e.target.value })}
                                value={auditInfo.processorGHz}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Monitor</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Monitor`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, monitor: e.target.value })}
                                value={auditInfo.monitor}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Monitor Type</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Monitor Type`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, monitorType: e.target.value })}
                                value={auditInfo.monitorType}
                            />
                        </div>
                    </div>
                </div>,
        },
        {
            key: '2',
            label: 'Asset Status',
            children:
                <div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Check Physical condition of System</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Check Physical condition of System`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, physicalCondition: e.target.value })}
                                value={auditInfo.physicalCondition}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Clean Temp Folder</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Clean Temp Folder`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, tempFolder: e.target.value })}
                                value={auditInfo.tempFolder}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Clean Antivirus Log Files</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Clean Antivirus Log Files`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, antivirusFiles: e.target.value })}
                                value={auditInfo.antivirusFiles}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Check Event Viewer Logs</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Check Event Viewer Logs`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, eventViewerLogs: e.target.value })}
                                value={auditInfo.eventViewerLogs}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Check Connectivity Status</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Check Connectivity Status`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, connectivityStatus: e.target.value })}
                                value={auditInfo.connectivityStatus}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Check CPU & Memory Status</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Check CPU & Memory Status`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, memoryStatus: e.target.value })}
                                value={auditInfo.memoryStatus}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Check CPU & Memory Usability Status</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Check CPU & Memory Usability Status`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, memoryUsabilityStatus: e.target.value })}
                                value={auditInfo.memoryUsabilityStatus}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Check C Drive Space Status(Free Space/Total Space)</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Check C Drive Space Status(Free Space/Total Space)`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, driveSpaceStatus: e.target.value })}
                                value={auditInfo.driveSpaceStatus}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Delete explore/Chrome Log File</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Delete explore/Chrome Log File`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, chromeLogFile: e.target.value })}
                                value={auditInfo.chromeLogFile}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Check Existence of any Irrelevant Files & Softwares</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Check Existence of any Irrelevant Files & Softwares`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, irrelevantFiles: e.target.value })}
                                value={auditInfo.irrelevantFiles}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Check Windows Update Status</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Check Windows Update Status`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, windowsUpdateStatus: e.target.value })}
                                value={auditInfo.windowsUpdateStatus}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Match Asset Code with System Record</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Match Asset Code with System Record`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, systemRecord: e.target.value })}
                                value={auditInfo.systemRecord}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Check & ensure Asset Plugged to UPS</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Check & ensure Asset Plugged to UPS`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, assetPluggedUps: e.target.value })}
                                value={auditInfo.assetPluggedUps}
                            />
                        </div>
                    </div>
                </div>,
        },
        {
            key: '3',
            label: 'Extra Info',
            children:
                <div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">any Others</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter any Others`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, anyOthers: e.target.value })}
                                value={auditInfo.anyOthers}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">System Serial Number</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter System Serial Number`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, systemSerialNumber: e.target.value })}
                                value={auditInfo.systemSerialNumber}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">MAC Address</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter MAC Address`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, macAddress: e.target.value })}
                                value={auditInfo.macAddress}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Tight VNC Installed</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Tight VNC Installed`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, tightVnc: e.target.value })}
                                value={auditInfo.tightVnc}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Antivirus installed and up to date</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Antivirus installed and up to date`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, antivirusInstalled: e.target.value })}
                                value={auditInfo.antivirusInstalled}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Assetze client installed</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Assetze client installed`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, clientInstalled: e.target.value })}
                                value={auditInfo.clientInstalled}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Preventive Engineer Name</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Preventive Engineer Name`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, preventiveEngName: e.target.value })}
                                value={auditInfo.preventiveEngName}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">User Email ID</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter User Email ID`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, userEmail: e.target.value })}
                                value={auditInfo.userEmail}
                            />
                        </div>
                    </div>
                    <div className="audit-info-container">
                        <div className="audit-info-name">Remarks</div>
                        <div className='audit-info-input'>
                            <Input style={{ background: "white" }}
                                placeholder={`Enter Remarks`}
                                onChange={(e) => setAuditInfo({ ...auditInfo, remarks: e.target.value })}
                                value={auditInfo.remarks}
                            />
                        </div>
                    </div>
                </div>,
        },
    ];

    const props = {
        name: 'file',
        beforeUpload: (file, fileList) => {
            for (let i = 0; i < fileList.length; i++) {
                if (fileList[i].size > 1e6) {
                    dispatch(
                        showSnack('INVALID_HEALTH', {
                            label: 'File Size should be less than 1MB',
                            timeout: 7000,
                        })
                    );
                    return Upload.LIST_IGNORE;
                }
            }
            // setFileList([...fileList, file]);

            return false;
        },
        onChange: (info) => {
            console.log(info);

            setFileList(info.fileList);
        },
        fileList,
        // onRemove: (file) => {
        //     setFileList([...fileList.filter(el => el.uid !== file.uid)]);
        //     return false
        // },
    };


    return (
        <>
            <Card
                style={{ marginBottom: "40px" }}
                title="Audit History"
                extra={
                    <Space>
                        <Button style={{ backgroundColor: 'white' }} onClick={onhandleCancel}>
                            Cancel
                        </Button>
                        <Button className="ant-design-button"
                            onClick={() => {
                                OnhandleSave()
                            }}>
                            Save
                        </Button>
                    </Space>
                }
            >
                <div className="audit-health-div">
                    <div className="audit-health-innerdiv">
                        {healthInfo.map((el, index) => {
                            const healthConf = defaultHealth.find((elHealth) => elHealth.alias === el.alias);

                            if (!healthConf) {
                                return null;
                            }
                            return (
                                <div className="each-health-container" key={index}>
                                    <div className="health-name">{healthConf.key}</div>
                                    <div className="input-div">
                                        {healthConf.type === 'dropdown' ? (
                                            <Select
                                                value={healthConf.inputs.filter((c) =>
                                                    el.value ? el.value.toLowerCase().includes(c.value) : null
                                                )}
                                                onChange={(val) => {
                                                    updateHealthInfo(el, 'dropdown', val);
                                                }}
                                                placeholder={`Select ${el.key}`}
                                                className={'audit-health-select'}
                                                options={healthConf.inputs}
                                            />
                                        ) : null}
                                        <Input
                                            onChange={(e) => {
                                                updateHealthInfo(el, 'comment', e.target.value);
                                            }}
                                            className="audit-health-comments"
                                            placeholder={'Comments'}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <Upload listType={"picture"} maxCount={2} accept='image/png,image/jpeg'  {...props}>
                            <Button style={{ background: "white" }} icon={<UploadOutlined />}>Upload Image</Button>
                        </Upload>
                        <hr></hr>
                        <p style={{ marginTop: "30px", fontSize: "20px" }}>Preventive Maintenance</p>
                        <Collapse style={{ fontFamily: "League Spartan", marginTop: "15px" }} items={items} />
                    </div>
                    <p className="health-note-on-expiry">
                        Note: Health score also depends on the number of years of usage and the warrarnty period
                        remaining*
                    </p>
                </div>
            </Card>
        </>
    );
};