import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import MobileHeader from '../Scanner/MobileHeader'
import { Button, Divider, Input, Select, Table } from 'antd';
import QrFrame from '../../images/scanner/qr-frame.svg';
import QrScanner from 'qr-scanner';
import { getApi, postApi } from '../../services/api';
import { debounce } from 'lodash';
import { MdDeleteOutline } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';


const DisposeAsset = () => {
    const [openQr, setOpenQr] = useState(false);
    const [selectVal, setSelectVal] = useState(null);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [options, setOptions] = useState([]);

    const filterSerial = debounce(async (filterText) => {
        if (filterText.length > 0) {
            const url = `/assets/get-assets?filterText=${filterText}`;
            const resp = await getApi(url);
            if (resp.type === 'success') {
                setOptions(resp.assets.map(el => ({
                    label: el.serialNumber,
                    value: el.serialNumber,
                    assetId: el.id,
                    empName: el.employee.name,
                    assetName: el.name,
                })));
            }
        }
    }, 1000);

    console.log(selectedAssets);


    return (
        <div className="qr-scanner-div">
            <div className="main-container">
                <img className="main-bg-image" alt={'bg'} src={require('../../images/scanner/bg-2.png')} />
                <div className="main-content">
                    <MobileHeader />
                    {!showTable ?
                        <div className='dispose-asset-div'>
                            <div className='heading'>Dispose Assets</div>
                            <div style={{ padding: "0 10px" }}>
                                <div style={{ marginTop: "20px" }} className='text'>Total Assets Added: {selectedAssets.length}</div>
                                <div className='label' >Select serial numbers</div>
                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <Select
                                        showSearch
                                        value={selectVal}
                                        options={options}
                                        filterOption={false}
                                        style={{ width: "100%" }}
                                        onSearch={(text) => { filterSerial(text); }}
                                        onChange={(val, newAsset) => {
                                            const isPresent = selectedAssets.some(el => el.value === newAsset.value);
                                            if (!isPresent) setSelectedAssets(prev => [...prev, newAsset]);
                                            setSelectVal(null);
                                        }}
                                        size='large'
                                        placeholder='Select Serial Numbers' />
                                </div>
                                <Divider style={{ marginTop: "20px" }} >Or</Divider>

                                <div className='label'>Add asset using QR code</div>
                                <div onClick={() => setOpenQr(!openQr)} className="scanner-div">
                                    {openQr ? (
                                        <QrReader setSelectedAssets={setSelectedAssets} setOpenQr={setOpenQr} />
                                    ) : (
                                        <div>
                                            <img
                                                style={{ borderRadius: '30px' }} alt='scanner'
                                                src={require('../../images/scanner/scanner-image.jpeg')}
                                            />{' '}
                                        </div>
                                    )}
                                </div>
                                <div style={{ display: "flex", justifyContent: "right", marginTop: "40px" }}>

                                    <Button size='large' onClick={() => { setShowTable(true); }} type='primary'>Proceed</Button>
                                </div>
                            </div>
                        </div> :
                        <DisposeTable selectedAssets={selectedAssets} />
                    }
                </div>
            </div>
        </div >
    )
}

export default DisposeAsset

const DisposeTable = ({ selectedAssets }) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        console.log('ok');

        setData(
            selectedAssets.map(el => ({
                key: el.value,
                serialNo: el.value,
                assetName: el.assetName,
                empName: el.empName,
                assetId: el.assetId,
                actions: <MdDeleteOutline
                    onClick={() => {
                        removeData(el.value);
                    }}
                    style={{ cursor: 'pointer', fontSize: '19px' }}
                />
            })));
    }, [])

    const removeData = (val) => {
        console.log(val);

        setData(prevData => prevData.filter(el => el.key !== val));
    }

    console.log(data);


    const columns = [
        {
            title: 'Serial.No',
            dataIndex: 'serialNo',
            key: 'serialNo',
        },
        {
            title: 'Asset Name',
            dataIndex: 'assetName',
            // width: "30%",
            key: 'assetName',
        },
        {
            title: 'Employee',
            dataIndex: 'empName',
            key: 'empName',
        },
        {
            title: 'AssetId',
            dataIndex: 'assetId',
            key: 'assetId',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: "10%",
            align: 'center',
        },
    ];

    return (
        <div className='dispose-asset-table' >
            <Table columns={columns} dataSource={data} scroll={{ x: 550 }} pagination={false} />
        </div>
    )
}

const QrReader = ({ setSelectedAssets, setOpenQr }) => {
    // QR States
    const dispatch = useDispatch();
    const scanner = useRef();
    const videoEl = useRef(null);
    const qrBoxEl = useRef(null);
    const [qrOn, setQrOn] = useState(true);

    // Result
    const [scannedResult, setScannedResult] = useState('');

    // Success
    const onScanSuccess = (result) => {
        console.log(result);
        setScannedResult(result?.data);
        console.log(atob(result?.data));
        const decodedData = atob(result?.data);
        var parsedData = JSON.parse(decodedData);
        console.log(parsedData._id);
        getAssetDetails(parsedData._id);

        setOpenQr(false);
    };

    const getAssetDetails = async function (asset_id) {
        const resp = await postApi({ assetId: asset_id }, '/assets/get-asset');
        if (resp.type === 'success') {
            console.log(resp.data, 'resp.data');
            const data = {
                label: resp.data.serialNumber,
                value: resp.data.serialNumber,
                assetId: resp.data.id,
                empName: resp.data.employee.name,
                assetName: resp.data.name,
            }
            setSelectedAssets(prev => [...prev, data]);
            dispatch(
                showSnack('INVALID_HEALTH', {
                    label: 'Asset Added',
                    timeout: 3000,
                })
            );
        } else {
            dispatch(
                showSnack('INVALID_HEALTH', {
                    label: 'Asset Not found',
                    timeout: 7000,
                })
            );
        }
    };

    const onScanFail = (err) => {
        console.log(err);
    };

    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
            scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
                onDecodeError: onScanFail,
                preferredCamera: 'environment',
                highlightScanRegion: true,
                highlightCodeOutline: true,
                overlay: qrBoxEl?.current || undefined,
            });

            // 🚀 Start QR Scanner
            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        return () => {
            if (!videoEl?.current) {
                scanner?.current?.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (!qrOn)
            alert('Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.');

        console.log(scanner.current);
    }, [qrOn]);

    return (
        <div className="qr-reader">
            {/* QR */}
            <video ref={videoEl}></video>
            <div ref={qrBoxEl} className="qr-box">
                <img src={QrFrame} alt="Qr Frame" width={256} height={256} className="qr-frame" />
            </div>

            {/* Show Data Result if scan is success */}
            {scannedResult && (
                <p
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 99999,
                        color: 'white',
                    }}
                >
                    Scanned Result: {scannedResult}
                </p>
            )}
        </div>
    );
};