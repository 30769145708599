import React, { useState, useEffect, useRef } from 'react';
import FooterDashboard from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import { useForm, Controller } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles, createStyles, createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { IoCloudUploadOutline } from 'react-icons/io5';
// import Select from 'react-select';
// import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { getApi, postApi } from '../../services/api';
import { showSnack } from 'react-redux-snackbar';
import { useDispatch } from 'react-redux';
import teal from '@material-ui/core/colors/teal';
import { AddCustomConfiguration } from '../EditAssetScreen';
import { SearchEmployeeSelct } from '../../components/SearchEmployee';
import { SearchInvoicePO } from '../../components/SearchPO';
import { SearchOfficeLoc } from '../../components/SearchOffice';
import { Button, Input, Upload, DatePicker, Select, Modal, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getInvoice, getOfficeLoc } from '../../services/assets';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { format } from 'date-fns';
const dateFormat = 'YYYY/MM/DD';
dayjs.extend(customParseFormat);

const { TextArea } = Input;
const { Dragger } = Upload;

const defaultMaterialTheme = createMuiTheme({
	palette: {
		primary: teal,
	},
});

const useStyles = makeStyles(() =>
	createStyles({
		noBorder: {
			outline: 'none',
			border: 'none',
			color: '#fff',
		},
	})
);

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0px',
		boxShadow: '0 0 5px #7f7f7f',
	},
	overlay: {
		backgroundColor: '#4e4e4e65',
	},
};

const options = [
	{ value: 'tablet', label: 'Tablet' },
	{ value: 'laptop', label: 'Laptop' },
	{ value: 'mobile', label: 'Mobile' },
];

const convertOptionInputs = function (el) {
	return { value: el, label: el.charAt(0).toUpperCase() + el.slice(1) };
};

const defaultHealth = [
	{
		key: 'Condition',
		value: '',
		comment: 'fine',
		alias: 'H1',
		type: 'dropdown',
		inputs: ['working', 'repaired', 'dead'].map(convertOptionInputs),
	},
	{
		key: 'Internal Parts',
		value: '',
		comment: 'fine checked on 26th september',
		alias: 'H2',
		type: 'dropdown',
		inputs: ['present', 'damaged', 'missing'].map(convertOptionInputs),
	},
	{
		key: 'Physical Damage',
		value: '',
		comment: '',
		alias: 'H3',
		type: 'dropdown',
		inputs: ['yes', 'no'].map(convertOptionInputs),
	},
	{
		key: 'Software Issue',
		value: '',
		comment: '',
		alias: 'H4',
		type: 'dropdown',
		inputs: ['yes', 'no'].map(convertOptionInputs),
	},
	{
		key: 'Is the product ready to be disposed?',
		value: '',
		comment: '',
		alias: 'H5',
		type: 'dropdown',
		inputs: ['yes', 'no', 'already disposed'].map(convertOptionInputs),
	},
];

const AddHealth = ({ setCustomHealth, healthInfoExist }) => {
	const [showModal, setShowModal] = useState(false);
	const [healthInfo, setHealthInfo] = useState(healthInfoExist.length > 0 ? healthInfoExist : defaultHealth);
	const dispatch = useDispatch();

	const updateHealthInfo = function (selectedHealth, type, value) {
		setHealthInfo(
			healthInfo.map((eachHealth) => {
				if (eachHealth.alias !== selectedHealth.alias) {
					return eachHealth;
				}
				if (type === 'dropdown') {
					return {
						...eachHealth,
						value,
					};
				}
				if (type === 'comment') {
					return {
						...eachHealth,
						comment: value,
					};
				}
			})
		);
	};

	const saveHealthInfo = function () {
		//setCustomHealth
		for (let x = 0; x < healthInfo.length; x++) {
			const eachHealth = healthInfo[x];
			if (!eachHealth.value) {
				return dispatch(
					showSnack('INVALID_HEALTH', {
						label: 'Please fill all the health fields',
						timeout: 7000,
					})
				);
			}
		}
		setCustomHealth(healthInfo);
		setShowModal(false);
	};

	return (
		<>
			<Button
				onClick={() => {
					setShowModal(true);
				}}
				// type="button"
				size="large"
				type={"primary"}
			>
				+ Update
			</Button>
			<Modal
				open={showModal}
				onCancel={() => {
					setShowModal(false);
				}}
				footer={
					<Space>
						<Button onClick={() => setShowModal(false)} >
							Cancel
						</Button>
						<Button onClick={() => saveHealthInfo()} type="primary" >
							Save
						</Button>
					</Space>
				}
			// isOpen={showModal}
			// onRequestClose={() => {
			// 	setShowModal(false);
			// }}
			// style={customStyles}
			// contentLabel="Write Post"
			// ariaHideApp={false}
			>
				<div className="modal-health-container">
					<div className="head">Health</div>
					<div className="all-health-container">
						{healthInfo.map((el, index) => {
							return (
								<div className="each-health-container" key={index}>
									<div className="health-name">{el.key}</div>
									{el.type === 'dropdown' ? (
										<Select
											value={el.inputs.filter((c) =>
												el.value ? el.value.includes(c.value) : null
											)}
											onChange={(val) => {
												updateHealthInfo(el, 'dropdown', val);
											}}
											placeholder="Select..."
											className={'health-condition-options'}
											// styles={customStylesSelect}
											options={el.inputs}
										/>
									) : null}
									<Input
										onChange={(e) => {
											updateHealthInfo(el, 'comment', e.target.value);
										}}
										className="health-comments"
										placeholder={'Comments'}
									/>
								</div>
							);
						})}
					</div>
					<p className="health-note-on-expiry">
						Note: Health score also depends on the number of years of usage and the warrarnty period
						remaining*
					</p>
					<div className="health-actions">
						{/* <button onClick={() => setShowModal(false)} className="cancel">
							Cancel
						</button>
						<button onClick={() => saveHealthInfo()} className="save">
							Save
						</button> */}
						{/* <Button onClick={() => setShowModal(false)} >
							Cancel
						</Button>
						<Button onClick={() => saveHealthInfo()} type="primary" >
							Save
						</Button> */}
					</div>
				</div>
			</Modal>
		</>
	);
};

const configurationDefault = [
	{
		key: '',
		value: '',
		company: '',
		name: 'cpu',
		placeholder1: 'CPU',
		placeholder2: 'Clock Speed GHz',
		placeholder3: 'Company',
	},
	{
		key: '',
		value: '',
		company: '',
		name: 'ram',
		placeholder1: 'RAM',
		placeholder2: 'Clock Speed GHz',
		placeholder3: 'Company',
	},
	{
		key: '',
		name: 'hdd',
		value: '',
		company: '',
		placeholder1: 'HDD',
		placeholder2: 'Type',
		placeholder3: 'Company',
	},
	{
		key: '',
		value: '',
		company: '',
		name: 'graphics_Card',
		placeholder1: 'Graphic Card',
		placeholder2: 'Clock Speed GHz',
		placeholder3: 'Company',
	},
];

const AddConfiguration = function ({ setAllConfiguration }) {
	const [showModal, setShowModal] = useState(false);
	const [configurationList, updateConfigurationList] = useState(configurationDefault);
	const dispatch = useDispatch();

	const updateInputConfiguration = (e, key, itemIndex) => {
		updateConfigurationList(
			configurationList.map((el, index) => {
				if (index === itemIndex) {
					return { ...el, [key]: e.target.value };
				}
				return el;
			})
		);
	};

	const addNewConfig = () => {
		updateConfigurationList([
			...configurationList,
			{
				key: '',
				value: '',
				company: '',
				placeholder1: 'Custom Field',
				placeholder2: 'Custom Value',
				placeholder3: 'Custom Company',
			},
		]);
	};

	const saveConfigurationList = function () {
		for (let x = 0; x < configurationList.length; x++) {
			const eachConf = configurationList[x];
			if (!(eachConf.key && eachConf.value && eachConf.company)) {
				return dispatch(
					showSnack('CONFIGURATION_INVALID_INPUT', {
						label: 'Please fill all the configuration fields',
						timeout: 7000,
					})
				);
			}
		}

		setAllConfiguration(configurationList);
		setShowModal(false);
	};

	return (
		<>
			<button
				onClick={() => {
					setShowModal(true);
				}}
				type="button"
			>
				+ Add Custom Field
			</button>
			<Modal
				isOpen={showModal}
				onRequestClose={() => {
					setShowModal(false);
				}}
				style={customStyles}
				contentLabel="Write Post"
				ariaHideApp={false}
			>
				<div className="configuration-modal-container">
					<div className="head">Custom Fields</div>
					<div className="configuration-list-container">
						{configurationList.map((el, index) => {
							return (
								<div key={index}>
									<input
										onChange={(e) => {
											updateInputConfiguration(e, 'key', index);
										}}
										value={el.key}
										type={'text'}
										placeholder={el.placeholder1}
									/>
									<input
										onChange={(e) => {
											updateInputConfiguration(e, 'value', index);
										}}
										value={el.value}
										type={'text'}
										placeholder={el.placeholder2}
									/>
									<input
										onChange={(e) => {
											updateInputConfiguration(e, 'company', index);
										}}
										value={el.company}
										type={'text'}
										placeholder={el.placeholder3}
									/>
								</div>
							);
						})}
					</div>
					<button onClick={addNewConfig}>+Custom Input</button>
					<div className="actions">
						<button onClick={() => setShowModal(false)}>Cancel</button>
						<button
							onClick={() => {
								saveConfigurationList();
							}}
							className="save"
						>
							Save
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
};

const NewAssets = function () {
	const {
		register,
		handleSubmit,
		watch,
		control,
		setValue,
		getValues,
		formState: { errors },
	} = useForm({
		defaultValues: {
			dateOfPurchase: new Date(),
			employee: {
				dateOfAssign: new Date(),
			},
			warrantyExpiryDate: new Date(),
			defaultInvoiceInfo: { value: '', label: '' },
			defaultOfficeInfo: { value: '', label: '' },
			type: 'laptop'
		},
	});
	const todayDate = format(new Date(), 'yyyy-MM-dd')
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [selectedOffice, setSelectedOffice] = useState(null);
	const [allConfiguration, setAllConfiguration] = useState([]);
	const [healthInfo, setCustomHealth] = useState([]);
	const [confString, setConfString] = useState('');
	const textareaRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// useEffect(() => {
	// 	if (confString) {
	// 		textareaRef.current.style.height = '0px';
	// 		const scrollHeight = textareaRef.current.scrollHeight;
	// 		textareaRef.current.style.height = scrollHeight + 'px';
	// 	}
	// }, [confString]);
	useEffect(() => {
		let confList = '';
		for (let x = 0; x < allConfiguration.length; x++) {
			let tempStr = '';
			const eachConf = allConfiguration[x];
			tempStr += eachConf.key;
			tempStr += ',' + eachConf.value;
			// tempStr += ',' + eachConf.company;
			tempStr += '\n';
			confList += tempStr;
		}
		setConfString(confList);
	}, [allConfiguration]);

	const onSubmit = async (data) => {
		const body = data;
		console.log(body);

		body.configuration = [];
		body.custom = allConfiguration;
		// body.health = [
		// 	{ key: 'condition', value: 'working', comment: 'fine', alias: 'H1' },
		// 	{ key: 'internal parts', value: 'yes', comment: 'fine checked on 26th september', alias: 'H2' },
		// 	{ key: 'physical damage', value: 'no', comment: '', alias: 'H3' },
		// 	{ key: 'software issue', value: 'no', comment: '', alias: 'H4' },
		// 	{ key: 'is the product still in usable condition?', value: 'yes', comment: '', alias: 'H5' },
		// 	{ key: 'is this product ready to be disposed', value: 'alreadyDispose', comment: '', alias: 'H6' },
		// ];
		body.health = healthInfo;
		const resp = await postApi(body, '/assets/create');
		if (resp.type === 'error') {
			dispatch(
				showSnack('ASSETS_CREATE_FAILED', {
					label: resp.error,
					timeout: 7000,
				})
			);
		} else {
			dispatch(
				showSnack('ASSETS_CREATE_SUCCESS', {
					label: 'Successfully created Assets',
					timeout: 7000,
				})
			);
			navigate('/assets', { replace: true });
		}
	};
	const setEmployeeId = (id) => {
		setValue('employee.id', id);
	};
	const setInvoiceId = async (id) => {
		try {
			const invoiceFound = await getInvoice(id);

			console.log(invoiceFound, 'invoiceFound12');
			if (invoiceFound) {
				setSelectedInvoice(invoiceFound);
			}

			setValue('invoiceId', id);
		} catch (e) {
			console.error(e);
		}
	};
	const setOfficeLocation = async (id) => {
		try {
			const officeFound = await getOfficeLoc(id);

			if (officeFound) {
				setSelectedOffice(officeFound);
			}

			setValue('officeId', id);
		} catch (e) {
			console.error(e);
		}
	};

	const props = {
		name: 'file',
		headers: {
			authorization: 'authorization-text',
		},
		beforeUpload: (file) => {
			return false;
		},
	};
	const defaultInvoiceInfo = getValues('defaultInvoiceInfo');
	const defaultOfficeInfo = getValues('defaultOfficeInfo');

	return (
		<div className="dashboard-container">
			<HeaderComponent />
			{/* <div className="assets-header">
				<h3>Organization Assets</h3>
			</div> */}
			<div className="add-assest-container">
				<div className="add-assets-innerdiv">
					<div className="add-assest-header">
						<h2>Add New Asset</h2>
						<div className="add-assest-action">
							<button
								onClick={() => {
									navigate('/assets');
								}}
								className="cancel-btn"
							>
								Cancel
							</button>
							<button form="form1" type="submit" value="Submit" className="save-btn">
								Save
							</button>
						</div>
					</div>
					<form className="add-newasset-form" id="form1" onSubmit={handleSubmit(onSubmit)}>
						<SearchEmployeeSelct setEmployeeId={setEmployeeId} />

						{/* <div className="each-assest-row">
						<div className="each-assest-field">
							<label>Employee Name*</label>
							<input {...register('employee.name')} type="text" />
						</div>
						
						<div className="each-assest-field">
							<label>Employee Id*</label>
							<input {...register('employee.id')} type="text" />
						</div>
					</div> */}

						<h4 className="device-section-head">Device Section</h4>
						<div className="each-assest-row">
							<div className="each-assest-field">
								<label>Asset Type*</label>
								<AssetTypeFromBackend control={control} setValue={setValue} />

								{/* <input {...register('assestType')} type="text" /> */}
							</div>
							<div className="each-assest-field">
								<label>Asset *</label>
								<Input onChange={(e) => { setValue('name', e.target.value) }} type="text" />
							</div>
						</div>
						<div className="each-assest-row">
							<div className="each-assest-field">
								<label>Asset Id*</label>
								<Input onChange={(e) => { setValue('assestId', e.target.value) }} type="text" />
							</div>
							<div className="each-assest-field">
								<label>Department *</label>
								<Input onChange={(e) => { setValue('department', e.target.value) }} type="text" />
							</div>
						</div>
						<div className="each-assest-row">
							<div className="each-assest-field">
								<label>Custom Fields*</label>
								{allConfiguration.length > 0 ? (
									<TextArea
										autoSize={{
											minRows: 2,
											maxRows: 6,
										}}
										ref={textareaRef}
										className="conf-textare"
										readOnly
										// disabled
										value={confString}
									/>
								) : null}

								<AddCustomConfiguration
									configurationInfo={allConfiguration}
									setAllConfiguration={setAllConfiguration}
								/>
							</div>
							<div className="each-assest-field">
								<label>Serial Number *</label>
								<Input onChange={(e) => { setValue('serialNumber', e.target.value) }} type="text" />
							</div>
						</div>
						<div className="each-assest-row">
							<div className="each-assest-field">
								<label>Date Of Purchase*</label>
								<DatePicker
									placeholder="Provide Date"
									format="YYYY-MM-DD"
									defaultValue={dayjs(todayDate, dateFormat)}
									onChange={(val) => {
										setValue('dateOfPurchase', new Date(val))
									}}
									disabledDate={(current) => {
										return current && current > dayjs().endOf('day');
									}}
								/>
								{/* <Controller
									control={control}
									name="dateOfPurchase"
									render={({ field }) => {
										return (
											<ThemeProvider theme={defaultMaterialTheme}>
												<KeyboardDatePicker
													clearable
													value={field.value}
													placeholder="10/10/2018"
													onChange={(date) => field.onChange(date)}
													maxDate={new Date()}
													format="dd/MM/yyyy"
												/>
											</ThemeProvider>
										);
									}}
								/> */}
							</div>
							<div className="each-assest-field">
								<label>Date Of Assigning *</label>
								<DatePicker
									placeholder="Provide Date"
									format="YYYY-MM-DD"
									defaultValue={dayjs(todayDate, dateFormat)}
									onChange={(val) => {
										setValue('employee.dateOfAssign', new Date(val))
									}}
									disabledDate={(current) => {
										return current && current > dayjs().endOf('day');
									}}
								/>
								{/* <Controller
									control={control}
									name="employee.dateOfAssign"
									render={({ field }) => {
										return (
											<ThemeProvider theme={defaultMaterialTheme}>
												<KeyboardDatePicker
													clearable
													value={field.value}
													placeholder="10/10/2018"
													onChange={(date) => field.onChange(date)}
													maxDate={new Date()}
													format="dd/MM/yyyy"
												/>
											</ThemeProvider>
										);
									}}
								/> */}
							</div>
						</div>
						<div className="each-assest-row">
							<div className="each-assest-field">
								<label>Warranty Ending On*</label>
								<DatePicker
									placeholder="Provide Date"
									format="YYYY-MM-DD"
									defaultValue={dayjs(todayDate, dateFormat)}
									onChange={(val) => {
										setValue('warrantyExpiryDate', new Date(val))
									}}
								/>
								{/* <Controller
									control={control}
									name="warrantyExpiryDate"
									render={({ field }) => {
										return (
											<ThemeProvider theme={defaultMaterialTheme}>
												<KeyboardDatePicker
													clearable
													value={field.value}
													placeholder="10/10/2018"
													onChange={(date) => field.onChange(date)}
													format="dd/MM/yyyy"
												/>
											</ThemeProvider>
										);
									}}
								/> */}
							</div>
							{/* <div className="each-assest-field">
								<label>Vendor *</label>
								<input {...register('vendor')} type="text" />
							</div> */}
						</div>
						<div className="each-assest-row">
							<div className="each-assest-field">
								<label>Price*</label>
								<Input onChange={(e) => { setValue('price', e.target.value) }} type="text" />
							</div>
							<div className="each-assest-field">
								<label>IMEI Number</label>
								<Input onChange={(e) => { setValue('imeiNumber', e.target.value) }} type="text" />
							</div>
						</div>
						<SearchInvoicePO
							setInvoiceId={setInvoiceId}
							defaultInvoiceInfo={defaultInvoiceInfo}
							selectedInvoice={selectedInvoice}
						/>
						{/* <div className="add-asset-dragger">
							<Dragger listType="picture" accept="image/png,image/jpeg" maxCount={4} {...props}>
								<p className="ant-upload-drag-icon">
									<IoCloudUploadOutline fontSize={20} />
								</p>
								<p className="ant-upload-text">Click or drag to Upload Asset Images.</p>
							</Dragger>
						</div>
						<h4>Asset Depreciation</h4>
						<div className="each-assest-row">
							<div className="each-assest-field">
								<label>Depreciation% per year*</label>
								<input {...register('depreciationInPer')} type="text" />
							</div>
							<div className="each-assest-field">
								<label>Salvage Percentage*</label>
								<input {...register('salvagePer')} type="text" />
							</div>
						</div> */}
						{/* <div style={{ width: "400px", marginTop: "30px" }}>

							<Upload onPreview={false} listType={"picture"} maxCount={4} accept='image/png,image/jpeg' {...props}>
								<Button icon={<UploadOutlined />}>Upload Image</Button>
							</Upload>
						</div> */}
						<div className="additional-container">
							<h4>Additional Information</h4>
							<div className="each-assest-field">
								<label>Health</label>
								<AddHealth healthInfoExist={healthInfo} setCustomHealth={setCustomHealth} />
							</div>
							<SearchOfficeLoc
								defaultOfficeInfo={defaultOfficeInfo}
								setOfficeLocation={setOfficeLocation}
								selectedOffice={selectedOffice}
							/>
						</div>
					</form>
				</div>
			</div>
			<FooterDashboard />
		</div>
	);
};

const AssetTypeFromBackend = ({ control, setValue }) => {
	const [assetTypes, setAssetTypes] = useState([]);
	const dispatch = useDispatch();
	useEffect(() => {
		async function getAssetTypes() {
			try {
				const resp = await getApi('/assetze/admin-setup/category');
				if (resp.type === 'success') {
					setAssetTypes(resp.categories);
				} else {
					dispatch(
						showSnack('FILE_UPLOAD_ERROR', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
			} catch (e) {
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: 'Something went wrong',
						timeout: 7000,
					})
				);
			}
		}

		getAssetTypes();
	}, []);

	const allOptions = options.concat(
		assetTypes.map((el) => {
			return {
				value: el.key,
				label: el.key,
			};
		})
	);
	return (<>
		{/* <Controller
			render={({ field: { onChange, value, ref } }) => {
				return (
					<Select
						inputRef={ref}
						value={allOptions.filter((c) => (value ? value.includes(c.value) : null))}
						onChange={(val) => {
							onChange(val.value);
						}}
						options={allOptions}
					/>
				);
			}}
			control={control}
			name="type"
			defaultValue={'laptop'}
		/> */}
		<Select
			showSearch
			onChange={(val) => setValue('type', val)}
			defaultValue={'laptop'}
			filterOption={(input, option) =>
				(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
			}
			// inputRef={ref}
			// value={allOptions.filter((c) => (value ? value.includes(c.value) : null))}
			// onChange={(val) => {
			// onChange(val.value);
			// }}
			size="large"
			options={allOptions}
		/>
	</>
	);
};

export default NewAssets;
