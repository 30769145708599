/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import './index.css';
import DeviceUserDetails from '../../images/drawer-image.png';
import StarRatings from 'react-star-ratings';
import { format, differenceInDays } from 'date-fns';
// import Modal from 'react-modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { download, getApi, postApi, uploadToS3 } from '../../services/api';
import { ApiPost, DownloadDeprReport } from '../../services/agentConfig';
import _, { isArray, isEmpty } from 'lodash';
import { showSnack } from 'react-redux-snackbar';
import { useDispatch } from 'react-redux';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { makeStyles, createStyles, createMuiTheme } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Controller, useForm } from 'react-hook-form';
import clock from '../../images/Clock.svg';
import { el } from 'date-fns/locale';
import { DownloadAssetTag } from './DownloadAssetTag';
import {
	Tabs,
	ConfigProvider,
	Steps,
	Table,
	Progress,
	Input,
	DatePicker,
	Modal,
	Checkbox,
	Select,
	Button,
	Card,
	Space,
	Tag,
	Form,
	Col,
	Row,
	Popover,
	Collapse,
	Upload,
	Dropdown,
} from 'antd';
import { CiEdit } from 'react-icons/ci';
import { MdOutlineCancel } from 'react-icons/md';
import prettyBytes from 'pretty-bytes';
import { EditTwoTone, SearchOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
// import { Chart } from 'react-charts';
import dayjs from 'dayjs';
import 'chart.js/auto';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Map from '../../components/Map';
import MapViewUI from '../../components/Map';
import Depreciation from './Depreciation';
import {
	ACTIVITY_VIEW,
	ASSET_ASSIGN_UNASSIGN,
	AUDIT_CREATE,
	AUDIT_VIEW,
	COMMENT_EDIT,
	COMMENT_VIEW,
	CheckPermission,
	CheckPermissionCondition,
	DEPRECIATION_VIEW,
	HEALTH_VIEW,
	IMAGE_CREATE,
	IMAGE_VIEW,
	LOCATION_VIEW,
	PROGRAMS_VIEW,
	RAM_VIEW,
	USERS_VIEW,
} from '../../utils/Permissions';
import { UploadOutlined } from '@ant-design/icons';
import DeviceHistory from './DeviceHistory';
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';

// import moment from 'moment';
// ChartJS.register(ArcElement);

const defaultMaterialTheme = createMuiTheme({
	palette: {
		primary: teal,
	},
});

const DEFAULT_ASSET_IMAGES = [
	'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg1.png',
	'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg2.png',
	'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg3.png',
	'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg4.png',
];

const getConfsList = function (data) {
	if (data.length > 0) {
		return null;
	}
	return data;
};

const getHealthCondition = function (healths) {
	const conditionFound = healths.find((el) => el.alias == 'H1' || el.alias == 'H001');
	if (conditionFound) {
		return conditionFound.value;
	}
};

function getImageList(asset) {
	if (asset && asset.images.length > 0) {
		const resp = [...asset.images, ...DEFAULT_ASSET_IMAGES.slice(0, 4 - asset.images.length)];
		console.log(resp, '1212');
		return resp.slice(0, 4);
	}
	return DEFAULT_ASSET_IMAGES;
}

const initialAssetUserData = [] || [
	{
		userName: 'Santhosh H',
		assetId: 'EC-001',
		userEmail: 'santhosh@ecorework.com',
		assetInfo: '10240 00221',
		assigneDate: '24 Feb 2021',
		department: 'Sales',
		shift: 'Indian Shift',
		loginTime: '9 AM',
		logoutTime: '5 PM',
		shiftType: 'morning',
		shiftIndex: 1,
		backgroundColor: '#F8F988',
		backgroundColor2: '#FFFFCF',
		backgroundColor3: '#FFFFCF',
		time: 8,
	},
	{
		userName: 'Nesara',
		assetId: 'EC-001',
		userEmail: 'nesara@ecorework.com',
		assetInfo: '10240 00221',
		assigneDate: '24 Feb 2021',
		department: 'Sales',
		shift: 'European Shift',
		loginTime: '5 PM',
		logoutTime: '1 AM',
		shiftType: 'evening',
		shiftIndex: 2,
		backgroundColor: '#F2D388',
		backgroundColor2: '#FFF4DB',
		backgroundColor3: '#FFF4DB',
		time: 8,
	},
	{
		userName: 'Puneeth Reddy',
		assetId: 'EC-001',
		userEmail: 'punith@ecorework.com',
		assetInfo: '10240 00221',
		assigneDate: '24 Feb 2021',
		department: 'Sales',
		shift: 'US Shift',
		loginTime: '1 AM',
		logoutTime: '9 AM',
		shiftType: 'night',
		shiftIndex: 3,
		backgroundColor: '#5C2E7E',
		backgroundColor2: '#F7EBFF',
		backgroundColor3: '#EFDAFE',
		time: 6,
	},
	{
		userName: 'Nithin',
		assetId: 'EC-001',
		userEmail: 'nithin@ecorework.com',
		assetInfo: '10240 00221',
		assigneDate: '24 Feb 2021',
		department: 'Sales',
		shift: 'US Shift',
		loginTime: '3 PM',
		logoutTime: '5 PM',
		shiftType: 'custom',
		shiftIndex: 4,
		backgroundColor: '#DBFFF4',
		backgroundColor2: '#DBFFF4',
		backgroundColor3: '#FFFFCF',
		time: 2,
	},
];

const TemporaryDrawer = function ({ selectedAsset, updateAsset, archievedAsset = false }) {
	const [AssetImg, setAssetImg] = useState(0);
	const [dateValue, setDateValue] = useState('');
	const [openModal, setOpenModal] = useState(false);
	const [assignModal, setAssignModal] = useState(false);
	const [exitassignModal, setExitAssignModal] = useState(false);
	const [employeeList, setEmployeeList] = useState([]);
	const [employeeSelected, setSelectedEmployee] = useState(null);
	const [assignTemporarily, setTemporarilyAssign] = useState(false);
	const [devlocationInfo, setLocationInfo] = useState({});
	const [lastUsageDate, setLastUsageDate] = useState(new Date());
	const [dualUserModal, setDualUserModal] = useState(false);
	const [temproaryAssign, setTemproaryAssign] = useState(false);
	const [customTime, setCustomTime] = useState(false);
	const [shiftModal, setShiftModal] = useState(false);
	const [shiftData, setShiftData] = useState(initialAssetUserData);
	const [shiftSelected, setShiftSelected] = useState(null);
	const [searchLoading, setSearchLoading] = useState(false);
	const [dateofExit, setDateofExit] = useState('');
	const [softwareData, setSoftwareData] = useState([]);
	const [usersData, setUsersData] = useState([]);
	const [diskData, setDiskData] = useState([]);
	const [fullInfo, setFullInfo] = useState({});
	const [memoryInfo, setMemoryInfo] = useState([]);
	const [systemInfo, setSystemInfo] = useState({});
	const [scheduledUnassign, setScheduledUnassign] = useState(false);
	const [replaceUnassign, setReplaceUnassign] = useState(false);
	const [replaceAsset, setReplaceAsset] = useState(null);
	const [searchText, setSearchText] = useState('');
	const [openAlert, setOpenAlert] = useState(false);
	const [assetsLoading, setAssetsLoading] = useState(false);
	const [assetsList, setAssetsList] = useState([]);
	const [selectedOption, setSelectedOption] = useState(0);

	const dispatch = useDispatch();
	const { Search } = Input;
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	useEffect(() => {
		getSoftwareData();
		getUsersData();
		getFull();
	}, []);

	async function getAssetTypes(text) {
		if (text) {
			try {
				const resp = await getApi(`/assets/search-assets?assetName=${text}&instock=yes`);
				if (resp.type === 'success') {
					console.log(resp?.assets);
					setAssetsList(resp?.assets);
				} else {
					dispatch(
						showSnack('ASSET_SEARCH_ERROR', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
			} catch (e) {
				dispatch(
					showSnack('ASSET_SEARCH_ERROR', {
						label: 'Something went wrong',
						timeout: 7000,
					})
				);
			}
			setAssetsLoading(false);
		} else {
			setAssetsLoading(false);
		}
	}

	const getSoftwareData = async function () {
		const serialNumber = selectedAsset.serialNumber;
		try {
			const resp = await ApiPost({ serialNumber }, '/assetze/asset-history/agent-program-data');
			if (resp.type === 'success') {
				setSoftwareData(resp.data);
			}
			return null;
		} catch (e) {
			console.error(e);
		}
	};

	const getUsersData = async function () {
		const serialNumber = selectedAsset.serialNumber;
		try {
			const resp = await ApiPost({ serialNumber }, '/assetze/asset-history/agent-user-data');
			if (resp.type === 'success') {
				setUsersData(resp.data);
			}
			return null;
		} catch (e) {
			console.error(e);
		}
	};

	const getFull = async function () {
		try {
			const resp = await ApiPost(
				{ serialNumber: selectedAsset.serialNumber },
				'/assetze/asset-history/agent-conf-data'
			);
			console.log(resp);
			if (resp.type === 'success') {
				setDiskData(resp.data.disks);
				setMemoryInfo(resp.data.memoryInfo);
				setSystemInfo(resp.data.systemInfo);
				setFullInfo(resp.data);

				if (resp.data.systemInfo?.deviceLocation) {
					setLocationInfo(resp.data.systemInfo?.deviceLocation);
				}
			}
			return null;
		} catch (e) {
			console.error(e);
		}
	};

	const apiData = {
		// labels: ["Morning Shift", "Evening Shift", "Night Shift"],
		datasets: [
			{
				data: initialAssetUserData.map((el) => {
					return el.time;
				}),

				backgroundColor: initialAssetUserData.map((el) => {
					console.log(shiftSelected, el.shiftIndex);
					if (shiftSelected === el.shiftIndex) {
						return el.backgroundColor;
					}
					return '#F5F4F0';
				}),
				borderColor: initialAssetUserData.map((el) => {
					if (shiftSelected === el.shiftIndex) {
						return el.backgroundColor;
					}
					return '#F5F4F0';
				}),
				borderWidth: 1,
				cutout: '81%',
				borderRadius: 50,
				offset: 15,
			},
		],
	};

	const handleOpen = () => {
		setOpenModal(true);
	};
	const handleClose = () => {
		setOpenModal(false);
		setSelectedOption(0);
	};

	const customStyles = {
		content: {
			top: '50%',
			width: 'auto',
			height: 'auto',
			padding: '0',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-50%, -50%)',
			backgroundColor: '#f8f8f8',
			overflow: 'hidden',
			boxShadow: '12px 12px 8px rgba(85, 85, 85, 0.12)',
			borderRadius: '5px',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			zIndex: 1201,
		},
	};

	const unassignAsset = async function (assetId, dateofExit) {
		try {
			const resp = await postApi({ assetId, dateofExit }, '/assets/unassign');
			if (resp.type === 'success') {
				if (resp.asset) {
					updateAsset(resp.asset);
					setOpenModal(false);
					dispatch(
						showSnack('INVALID_HEALTH', {
							label: 'Successfully Un Assigned Asset',
							timeout: 7000,
						})
					);
				} else {
					dispatch(
						showSnack('INVALID_HEALTH', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
				setSelectedOption(0);
				setScheduledUnassign(false);
			} else {
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const assignAsset = async function (assetId, employeeId) {
		try {
			const resp = await postApi(
				{ assetId, employeeId, assignTemporarily, dateValue, lastUsageDate },
				'/assets/assign'
			);
			if (resp.type === 'success') {
				if (resp.asset) {
					updateAsset(resp.asset);
					setAssignModal(false);
					clearAssignModel();
					dispatch(
						showSnack('INVALID_HEALTH', {
							label: 'Successfully Assigned Asset',
							timeout: 7000,
						})
					);
				} else {
					dispatch(
						showSnack('INVALID_HEALTH', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
			} else {
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) { }
	};

	const assignAssetWithDate = async function () {
		assignAsset(selectedAsset._id, employeeSelected._id);
	};

	const clearAssignModel = () => {
		setTemporarilyAssign(false);
		setSelectedEmployee(null);
		setEmployeeList([]);
		setLastUsageDate(new Date());
	};

	const searchEmployeeFilter = async (filterText) => {
		try {
			if (filterText && filterText.length >= 3) {
				const resp = await postApi({ filterText }, '/users/get-employee');
				console.log(resp);
				if (resp.type === 'success') {
					setSearchLoading(false);
					setEmployeeList(resp.employeeList);
				}
			} else {
				setSearchLoading(false);
				setEmployeeList([]);
			}
		} catch (e) { }
	};

	console.log(employeeList);

	const confs = selectedAsset.configuration.concat(selectedAsset.custom);

	const isWarrantyExpired = selectedAsset.warrantyExpiryDate
		? new Date(selectedAsset.warrantyExpiryDate) < new Date()
		: '';

	const assigned = selectedAsset && selectedAsset.employee;

	const updatedAssetImage = getImageList(selectedAsset);
	console.log(updatedAssetImage, 'updatedAssetImage');

	const openModalHandler = (shiftIndex) => {
		setShiftSelected(shiftIndex);
		setShiftModal(!shiftModal);
	};
	const options = {
		elements: {
			arc: {
				borderWidth: 0,
				cursor: 'pointer',
			},
		},

		onClick: (event, index) => {
			console.log('index', index[0]?.element?.$context?.dataIndex);
			setShiftSelected(initialAssetUserData[index[0]?.element?.$context?.dataIndex].shiftIndex);
		},
	};

	const selectedShiftFromUser = initialAssetUserData.find((el) => el.shiftIndex === shiftSelected);
	const shiftLoginTime = selectedShiftFromUser ? selectedShiftFromUser.loginTime.split(' ') : [];
	const shiftLogoutTime = selectedShiftFromUser ? selectedShiftFromUser.logoutTime.split(' ') : [];
	console.log('shiftLoginTime', shiftLoginTime);

	const SoftwareTabColumns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: '1',
			width: '30%',
		},
		{
			title: 'Version',
			dataIndex: 'version',
			key: '2',
			align: 'center',
		},
		{
			title: 'Publisher',
			dataIndex: 'publisher',
			key: '3',
			width: '30%',
			align: 'center',
		},
		{
			title: 'Install Date',
			dataIndex: 'installDate',
			key: '4',
			align: 'center',
		},
	];

	const SoftwareTabData = softwareData.map((data) => ({
		key: data._id,
		name: data.displayName,
		version: data.displayVersion,
		publisher: data.publisher,
		installDate: data.installedDate,
	}));

	const ConfigTabUsersColumn = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: '1',
			width: '10%',
			align: 'center',
		},
		{
			title: 'Domain',
			dataIndex: 'domain',
			key: '2',
			width: '15%',
			align: 'center',
		},

		{
			title: 'Status',
			dataIndex: 'status',
			key: '4',
			width: '7%',
			align: 'center',
		},
		{
			title: 'Disabled',
			dataIndex: 'disabled',
			key: '5',
			width: '5%',
			align: 'center',
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: '8',
			width: '20%',
			// align: 'center'
		},
		{
			title: 'Password Req.',
			dataIndex: 'PasswordReq',
			key: '7',
			width: '10%',
			align: 'center',
		},
		{
			title: 'Sid',
			dataIndex: 'sid',
			key: '3',
			// width: '20%',
			// align: 'center'
		},
	];

	const ConfigTabUsersData = usersData.map((data) => ({
		name: data.name,
		domain: data.domain,
		status:
			data.status === 'Degraded' ? <Tag color="red">{data.status}</Tag> : <Tag color="green">{data.status}</Tag>,
		description: data.description,
		key: data._id,
		PasswordReq: data.passwordRequired ? 'Yes' : 'No',
		disabled: data.disabled ? 'Yes' : 'No',
		sid: data.sid,
	}));

	const ConfigTabHardDiskColumn = [
		{
			title: 'Media',
			dataIndex: 'Media',
			key: '1',
			// width: '10%',
			align: 'center',
		},
		{
			title: 'Size',
			dataIndex: 'Size',
			key: '2',
			// width: '15%',
			align: 'center',
		},
		// {
		// 	title: 'Manufacturer',
		// 	dataIndex: 'Manufacturer',
		// 	key: '3',
		// 	width: '15%',
		// 	align: 'center',
		// },
		{
			title: 'Type',
			dataIndex: 'Type',
			key: '4',
			// width: '10%',
			align: 'center',
		},
		// {
		// 	title: 'Part No.',
		// 	dataIndex: 'PartNo',
		// 	key: '5',
		// 	width: '20%',
		// 	align: 'center',
		// },
		{
			title: 'File System',
			dataIndex: 'FileSystem',
			key: '6',
			// width: '10%',
			align: 'center',
		},
		{
			title: 'Used Space',
			dataIndex: 'UsedSpace',
			key: '7',
			// width: "25%",
			align: 'center',
		},
	];

	const totalHardDisk = diskData.reduce((sum, item) => sum + item.totalSpace, 0);
	const totalMemory = memoryInfo.reduce((sum, item) => sum + item.capacity, 0);

	const memoryInformation = {
		ram: totalHardDisk ? prettyBytes(totalHardDisk) : '',
		memory: totalMemory ? prettyBytes(totalMemory) : '',
	};

	const ConfigTabHardDiskData = diskData.map((data, index) => {
		const usedSpace = data.totalSpace - data.availableSpace;
		const percent = (usedSpace / data.totalSpace) * 100;
		return {
			UsedSpace: (
				<div className="progress-flexdiv">
					{Math.floor(percent)}%
					<Progress size="large" status="active" strokeColor="#33D7DB" percent={percent} showInfo={false} />
				</div>
			),
			key: data._id,
			Media: `Slot ${index + 1}`,
			Size: prettyBytes(data.totalSpace),
			// Manufacturer: 'Kingston',
			Type: data.kind,
			// PartNo: '99U5428-UF128B',
			FileSystem: data.filesystem,
		};
	});

	console.log(diskData);

	const ConfigTabMemoryData = memoryInfo.map((data, index) => ({
		Media: `Slot ${index + 1}`,
		Size: prettyBytes(data.capacity),
		Manufacturer: data.manufacturer,
		clockSpeed: data.configuredClockSpeed,
		PartNo: data.partNumber,
		serialno: data.serialNumber,
	}));

	const ConfigTabMemoryColumn = [
		{
			title: 'Media',
			dataIndex: 'Media',
			key: '1',
			width: '10%',
			align: 'center',
		},
		{
			title: 'Size',
			dataIndex: 'Size',
			key: '2',
			width: '15%',
			align: 'center',
		},
		{
			title: 'Manufacturer',
			dataIndex: 'Manufacturer',
			key: '3',
			width: '15%',
			align: 'center',
		},
		{
			title: 'Clock Speed',
			dataIndex: 'clockSpeed',
			key: '4',
			width: '15%',
			align: 'center',
		},
		{
			title: 'Part No.',
			dataIndex: 'PartNo',
			key: '5',
			width: '25%',
			align: 'center',
		},
		{
			title: 'Serial No.',
			dataIndex: 'serialno',
			key: '6',
			width: '20%',
			align: 'center',
		},
	];

	const ConfigTabItems = [
		{
			label: <div className="tab-options">Custom</div>,
			key: 1,
			children: (
				<>
					<div className="config-tab-custom">
						<ul>
							{confs &&
								confs.length > 0 &&
								confs.map((el, index) => {
									if (el.name) {
										return (
											<li key={index}>
												{el.name.replace(/-|_/, ' ').toUpperCase()} - {el.value}
											</li>
										);
									}
									return (
										<li key={index}>
											<b>{el.key.replace(/-|_/, ' ')}:</b> {el.value}
										</li>
									);
								})}
						</ul>
					</div>
				</>
			),
		},
		...(selectedAsset.agentLinked
			? [
				{
					label: <div className="tab-options">Device Specs</div>,
					key: 2,
					children: (
						<>
							<div className="config-device-specs">
								<div className="manufacturer">
									<div className="manu-label">Manufacturer:</div>
									<div className="manu-value">
										{systemInfo.manufacturer && systemInfo.manufacturer}
									</div>
								</div>
								<div className="innerdiv">
									<div className="flexdiv">
										<div className="label">Serial Number:</div>
										<div className="value">
											{systemInfo.serialNumber && systemInfo.serialNumber}
										</div>
									</div>
									<div className="flexdiv">
										<div className="label">Host Name:</div>
										<div className="value">{systemInfo.hostname && systemInfo.hostname}</div>
									</div>
									<div className="flexdiv">
										<div className="label">CPU Arch:</div>
										<div className="value">{systemInfo.cpuArch && systemInfo.cpuArch}</div>
									</div>
									<div className="flexdiv">
										<div className="label">Last Boot Up:</div>
										<div className="value">
											{systemInfo.lastBootUpTime &&
												format(new Date(systemInfo.lastBootUpTime), 'dd-MM-yyyy hh:mma')}
										</div>
										{/* <div className='value'>24-02-2024 04:34PM</div> */}
									</div>
								</div>
							</div>
						</>
					),
				},
				...(CheckPermissionCondition(RAM_VIEW)
					? [
						{
							label: <div className="tab-options">Memory</div>,
							key: 3,
							children: (
								<>
									<div className="config-tab-harddisk">
										<div className="upperdiv">
											<div className="total-hard-disk">
												<div className="label">Total-Memory:</div>
												<div className="value">
													{totalMemory && prettyBytes(totalMemory)}
												</div>
											</div>
											<div className="search-btn">
												<SearchOutlined />
											</div>
										</div>

										<Table
											dataSource={ConfigTabMemoryData}
											columns={ConfigTabMemoryColumn}
											scroll={{ x: 600 }}
										/>
									</div>
								</>
							),
						},
					]
					: []),
				{
					label: <div className="tab-options">Hard Disk</div>,
					key: 4,
					children: (
						<>
							<div className="config-tab-harddisk">
								<div className="upperdiv">
									<div className="total-hard-disk">
										<div className="label">Total Hard Disk:</div>
										<div className="value">{totalHardDisk && prettyBytes(totalHardDisk)}</div>
									</div>
									<div className="search-btn">
										<SearchOutlined />
									</div>
								</div>

								<Table dataSource={ConfigTabHardDiskData} columns={ConfigTabHardDiskColumn} />
							</div>
						</>
					),
				},
				...(CheckPermissionCondition(USERS_VIEW)
					? [
						{
							label: <div className="tab-options">Users</div>,
							key: 5,
							children: (
								<>
									<div className="config-tab-usersdiv">
										<Table
											scroll={{ x: 1000 }}
											dataSource={ConfigTabUsersData}
											columns={ConfigTabUsersColumn}
										/>
									</div>
								</>
							),
						},
					]
					: []),
				...(CheckPermissionCondition(LOCATION_VIEW)
					? [
						devlocationInfo && devlocationInfo.Latitude
							? {
								label: <div className="tab-options">Location</div>,
								key: 6,
								children: (
									<>
										<div className="config-tab-usersdiv">
											<MapViewUI devlocationInfo={devlocationInfo} />
										</div>
									</>
								),
							}
							: null,
					]
					: []),
			]
			: []),
	];

	const tabsOptions = [
		{
			key: '1',
			label: <div className="tab-options">Asset</div>,
			children: (
				<>
					<div className="asset-tab">
						<CheckPermission requiredPermission={IMAGE_VIEW}>
							<div className="asset-left-half">
								<img
									alt={'asset image selected'}
									src={updatedAssetImage[AssetImg]}
									className="pc-image"
								/>
								<AssetImages
									assetId={selectedAsset._id}
									AssetImg={AssetImg}
									setAssetImg={setAssetImg}
									updateAsset={updateAsset}
									assetsImages={updatedAssetImage}
								/>
							</div>
						</CheckPermission>
						<div className="asset-right-half">
							<div className="asset-device-name">{selectedAsset.name}</div>
							<div className="asset-device-id">{selectedAsset.serialNumber}</div>
							{/* <div className="asset-id"><div className='id-flexdiv'>{selectedAsset.id}<span><DownloadAssetTag assetId={selectedAsset._id} deviceId={selectedAsset.id} /></span></div></div> */}
							<div className="asset-id">
								<DownloadAssetTag assetId={selectedAsset._id} deviceId={selectedAsset.id} />
							</div>
							{/* <div className="asset-po">PO-138</div> */}
							{assigned || true ? (
								<div className="asset-innerdiv">
									<div className="asset-username">
										{selectedAsset.employee && selectedAsset.employee.name}
									</div>
									<div className="asset-userid">
										{selectedAsset.employee && selectedAsset.employee.id}
									</div>
									<div className="asset-user-email">
										{selectedAsset.employee && selectedAsset.employee.email}
									</div>
									{/* <div className='asset-user-no'>102400 45007</div> */}

									{selectedAsset.employee && selectedAsset.employee.dateOfAssign ? (
										<div className="asset-assigned-div">
											<div className="asset-assigned-on">Assigned on:</div>
											<div className="asset-assigned-date">
												{selectedAsset.employee &&
													selectedAsset.employee.dateOfAssign &&
													format(
														new Date(selectedAsset.employee.dateOfAssign),
														'dd MMM yyyy'
													)}
											</div>
										</div>
									) : null}

									{selectedAsset.employee ? (
										<div className="asset-department-div">
											<div className="asset-department">Department:</div>
											<div className="asset-department-value">
												{selectedAsset.employee && selectedAsset.department}
											</div>
										</div>
									) : null}

									<ShowLocationInfo officeId={selectedAsset.officeId} />
								</div>
							) : (
								<></>
							)}
							<div className="asset-btn">
								{archievedAsset ? null : (
									<>
										<CheckPermission requiredPermission={ASSET_ASSIGN_UNASSIGN}>
											{selectedAsset.employee && selectedAsset.employee.id ? (
												<button
													className="unassign-btn"
													onClick={() => {
														handleOpen();
													}}
												>
													Unassign Device
												</button>
											) : (
												<button
													className="unassign-btn"
													onClick={() => {
														setAssignModal(true);
													}}
												>
													Assign Device
												</button>
											)}
										</CheckPermission>
									</>
								)}
								{/* <button className="dispose-btn">Ready to Dispose</button> */}
							</div>
						</div>
					</div>
				</>
			),
		},
		{
			key: '2',
			label: <div className="tab-options">History</div>,
			children: (
				<>
					<DeviceHistory
						assetId={selectedAsset._id}
						selectedAsset={selectedAsset}
						fullInfo={fullInfo}
						memoryInformation={memoryInformation}
					/>
				</>
			),
		},
		{
			key: '3',
			label: <div className="tab-options">Configuration</div>,
			children: (
				<>
					<div className="tab-config">
						<Tabs
							defaultActiveKey="1"
							tabPosition={window.innerWidth < 600 ? 'top' : 'left'}
							// type='card'
							items={ConfigTabItems}
						/>
					</div>
				</>
			),
		},
		...(CheckPermissionCondition(HEALTH_VIEW)
			? [
				{
					key: '4',
					label: <div className="tab-options">Health</div>,
					children: (
						<>
							<div className="tab-health">
								<div className="health-outerdiv">
									<div className="health-tab-div">
										<div className="health-tab-ratingdiv">
											<div className="health-tab-text">Health</div>
											<StarRatings
												rating={selectedAsset.healthScore}
												starEmptyColor="rgb(204,204,204)"
												starRatedColor="rgb(253,211,5)"
												starDimension="30px"
												starSpacing="10px"
											/>
										</div>
										<div className="health-working-condition">
											{getHealthCondition(selectedAsset.health)} Condition
										</div>
									</div>
									<div className="health-textdiv">
										<CheckPermission requiredPermission={COMMENT_VIEW}>
											<AddComment
												updateAsset={updateAsset}
												comment={selectedAsset.comment}
												assetId={selectedAsset._id}
												dispatch={dispatch}
											/>
										</CheckPermission>
										<div>
											<div className="health-warranty-text">Warranty</div>
											<hr />
											<div className="health-purchase-date">
												Purchase Date:{' '}
												{selectedAsset.dateOfPurchase
													? format(new Date(selectedAsset.dateOfPurchase), 'dd MMM yyyy')
													: 'Date of Purchase not found'}
											</div>
											<div>
												{isWarrantyExpired ? null : (
													<div className="expire-date">
														<div className="date-text">Expires In:</div>

														<div className="date-num">
															{selectedAsset.warrantyExpiryDate
																? `${differenceInDays(
																	new Date(selectedAsset.warrantyExpiryDate),
																	new Date()
																)} days`
																: 'NA'}
														</div>
													</div>
												)}
											</div>
											<div className="health-status">
												Status:
												{isWarrantyExpired ? (
													<span>Out of Warranty</span>
												) : (
													selectedAsset.warrantyExpiryDate && (
														<span>Product Still in Warranty</span>
													)
												)}
											</div>
											<div className="health-steps">
												<Steps
													className="health-steps-antd"
													progressDot
													current={
														new Date(selectedAsset.warrantyExpiryDate) <= new Date()
															? 2
															: 1
													}
													items={[
														{
															title: 'Purchase Date',
															description:
																selectedAsset.dateOfPurchase &&
																format(
																	new Date(selectedAsset.dateOfPurchase),
																	'dd MMM yyyy'
																),
														},
														...(new Date(selectedAsset.warrantyExpiryDate) > new Date()
															? [
																{
																	title: "Today's Date",
																	description: format(
																		new Date(),
																		'dd MMM yyyy'
																	),
																},
															]
															: []),
														{
															title: (
																<div style={{ width: '160px' }}>
																	Warranty Expiry Date
																</div>
															),
															description:
																selectedAsset.warrantyExpiryDate &&
																format(
																	new Date(selectedAsset.warrantyExpiryDate),
																	'dd MMM yyyy'
																),
														},
													]}
												/>
											</div>
										</div>
									</div>
									<ShowVendorName invoiceId={selectedAsset.invoiceId} />
								</div>
							</div>
						</>
					),
				},
			]
			: []),
		...(selectedAsset.agentLinked && CheckPermissionCondition(PROGRAMS_VIEW)
			? [
				{
					key: '5',
					label: <div className="tab-options">Software</div>,
					children: (
						<>
							<div className="tab-software">
								<div className="software-innerdiv">
									<Table
										columns={SoftwareTabColumns}
										pagination={{
											pageSize: 8,
										}}
										dataSource={SoftwareTabData}
										scroll={{ x: 700 }}
									/>
								</div>
							</div>
						</>
					),
				},
			]
			: []),
		...(CheckPermissionCondition(DEPRECIATION_VIEW)
			? [
				{
					key: '6',
					label: <div className="tab-options">Depreciation</div>,
					children: (
						<>
							<Depreciation selectedAsset={selectedAsset} />
						</>
					),
				},
			]
			: []),
	];

	// console.log(moment('2020-06-09T12:40:14+0000'));
	console.log(new Date('2020-06-09T12:40:14+0000'));

	const onDateChange = (date, dateString) => {
		// console.log(date, dateString);
		setDateValue(new Date(dateString));
	};

	const onCheckboxChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
		setTemporarilyAssign(!assignTemporarily);
	};

	function disabledDate(current) {
		return current && current.valueOf() < Date.now();
	}

	const onReplacement = () => {
		const unassignAsset1 = async function (assetId, dateofExit) {
			try {
				const resp = await postApi({ assetId, dateofExit }, '/assets/unassign');
				if (resp.type === 'success') {
					if (resp.asset) {
						updateAsset(resp.asset);
						setOpenModal(false);
						assignAsset(replaceAsset._id, selectedAsset.employee.employeeId);
						setOpenAlert(false);
						setReplaceAsset(null);
						setReplaceUnassign(false);
					} else {
						dispatch(
							showSnack('INVALID_HEALTH', {
								label: resp.error,
								timeout: 7000,
							})
						);
					}
				} else {
					dispatch(
						showSnack('INVALID_HEALTH', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
			} catch (e) {
				console.error(e);
			}
		};
		unassignAsset1(selectedAsset._id);
	}

	console.log(selectedAsset);


	return (
		<div>
			<Modal
				open={assignModal}
				onOk={() => assignAssetWithDate()}
				onCancel={() => {
					setAssignModal(false);
					clearAssignModel();
				}}
				destroyOnClose={true}
				okButtonProps={{ style: { border: 'none' } }}
				footer={(_, { OkBtn, CancelBtn }) => (
					<>
						{employeeSelected ? (
							<>
								<CancelBtn />
								<OkBtn />
							</>
						) : (
							<></>
						)}
					</>
				)}
			>
				{employeeSelected ? (
					<div className="select-other-information">
						<h4 className="heading-text">Employee Selected</h4>
						<div style={{ position: 'relative' }} className="selected-employee">
							{employeeSelected.name ? <div>{employeeSelected.name}</div> : null}
							<MdOutlineCancel
								className="cross-btn"
								onClick={() => {
									setSelectedEmployee(false);
									setEmployeeList([]);
								}}
							/>
							<div>{employeeSelected.email}</div>
						</div>
						<div className="date-assign-div">
							<div>Schedule the date of assign: </div>
							<DatePicker onChange={onDateChange} />
						</div>
						<div className="temporarily-assigned">
							<Checkbox onChange={onCheckboxChange}>
								Do you want to assign this asset temporarily to the Employee?
							</Checkbox>
						</div>
						{assignTemporarily ? (
							<DatePicker
								onChange={(date) => {
									setLastUsageDate(new Date(date));
								}}
								size="large"
								disabledDate={disabledDate}
								style={{ marginLeft: '20px' }}
							/>
						) : null}
					</div>
				) : (
					<div className="search-employee">
						<h4 className="heading-text">Search For Employee</h4>
						<Search
							// type={'text'}
							size="large"
							loading={searchLoading}
							enterButton
							placeholder={'Employee Id/Employee Email'}
							// className={'search-employee-input'}
							onChange={_.debounce(function (e) {
								setSearchLoading(true);
								searchEmployeeFilter(e.target.value);
							}, 1000)}
						/>
						<div className="dropdown-options">
							{employeeList.map((employee, index) => {
								return (
									<div
										onClick={() => {
											setSelectedEmployee(employee);
										}}
										key={index}
										className={'each-employee'}
									>
										<div>
											{employee.name ? <div>{employee.name}</div> : null}
											<div>{employee.email}</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</Modal>
			<Modal
				open={openAlert}
				onCancel={() => {
					setOpenAlert(false);
				}}
				zIndex={2000}
				className='abcd'
				title={'Confirm Replacement'}
				footer={
					<Space >
						<Button onClick={() => {
							setOpenAlert(false);
						}}>Cancel</Button>
						<Button onClick={() => { onReplacement(); }} type='primary'>Submit</Button>
					</Space>
				}
			>
				Are you sure you want to replace asset <b>{selectedAsset?.name} [{selectedAsset?.id}]</b> with <b>{replaceAsset?.name} [{replaceAsset?.id}]</b> ?
			</Modal>

			<Modal
				footer={null}
				onCancel={handleClose}
				destroyOnClose={true}
				title={null}
				open={openModal}
			>
				<div>
					{scheduledUnassign ?
						(
							<div className="scheduled-unassign-div">
								<div className="heading-text">Please mention the date of exit</div>
								<div className='innerdiv'>
									<DatePicker
										style={{ width: '60%' }}
										placeholder='Select Scheduled Exit Date'
										onChange={(date, dateString) => {
											setDateofExit(dateString);
										}}
									/>
								</div>
								<div className='action-btns'>
									<Button onClick={() => { setScheduledUnassign(false) }}>Cancel</Button>
									<Button onClick={() => {
										unassignAsset(selectedAsset._id, dateofExit);
										// setExitAssignModal(false);
									}} type='primary'>Submit</Button>
								</div>
							</div>
						) : null}
					{scheduledUnassign || replaceUnassign ? null :
						(
							<div className='unassign-option-div'>
								<div className="heading-text">Reason for unassigning device?</div>
								<div onClick={() => {
									setSelectedOption(1);
								}} className={selectedOption === 1 ? 'selected-unassign-option' : 'unassign-options'}>
									<div>
										<div className='heading'>Unassign Asset</div>
										<div className='text'>Asset is immeditely unassigned</div>
									</div>
								</div>
								<div onClick={() => {
									setSelectedOption(2);
								}} className={selectedOption === 2 ? 'selected-unassign-option' : 'unassign-options'}>
									<div>
										<div className='heading'>Scheduled Exit</div>
										<div className='text'>Add Date for Scheduled unassign</div>
									</div>
								</div>
								<div onClick={() => {
									setSelectedOption(3);
								}} className={selectedOption === 3 ? 'selected-unassign-option' : 'unassign-options'}>
									<div>
										<div className='heading'>Replacement</div>
										<div className='text'>Replace with other asset</div>
									</div>
								</div>
							</div>
						)}
					{selectedOption ?
						(<div style={{ display: "flex", justifyContent: "right", gap: "10px" }}>
							<Button onClick={() => { setSelectedOption(0); setOpenModal(false); }}>Cancel</Button>
							<Button onClick={() => {
								if (selectedOption === 1) unassignAsset(selectedAsset._id);
								else if (selectedOption === 2) setScheduledUnassign(true);
								else if (selectedOption === 3) setReplaceUnassign(true);
								setSelectedOption(0);
							}} type='primary'>Next</Button>
						</div>) : null
					}

					{replaceUnassign ? (
						<div className='replacement-div'>
							{!replaceAsset ? (<>
								<div className="heading-text">Device Replacement</div>
								<Search
									loading={assetsLoading}
									enterButton
									placeholder={'Select Replacement Device'}
									onChange={_.debounce(function (e) {
										setAssetsLoading(true);
										getAssetTypes(e.target.value);
									}, 1000)}
								/></>) : null}
							<div>
								{replaceAsset ? (
									<div className='selected-asset-div'>
										<div className="heading-text">Selected Asset</div>
										<Space key={replaceAsset.id} className='replacement-asset-options'>
											<div>
												<img src={replaceAsset.images[0] || 'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg1.png'} style={{ width: '40px', height: '40px' }} />
											</div>
											<div className='div1'>
												<div>{replaceAsset.name}</div>
												<div>{replaceAsset.id}</div>
											</div>
											<div className='div2'>
												<div>{replaceAsset.serialNumber}</div>
												<div>8GB 256GB</div>
											</div>
										</Space>
									</div>
								) : null}
								{!replaceAsset ?
									(
										<div>
											<div className='select-div'>
												{assetsList.map((option) => {
													const imageUrl =
														option.images?.length > 0
															? option.images[0]
															: 'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg1.png';
													return (
														<Space key={option.id} onClick={() => { setReplaceAsset(option); }} className='replacement-asset-options'>
															<div>
																<img src={imageUrl} style={{ width: '40px', height: '40px' }} />
															</div>
															<div className='div1'>
																<div>{option.name}</div>
																<div>{option.id}</div>
															</div>
															<div className='div2'>
																<div>{option.serialNumber}</div>
																<div>8GB 256GB</div>
															</div>
														</Space>
													);
												})}
											</div>
											<div className='action-btns'>
												<Button onClick={() => { setReplaceUnassign(null); }}>Back</Button>
											</div>
										</div>
									) : null}
							</div>
							{replaceAsset ? (
								<div className='action-btns'>
									<Button onClick={() => { setReplaceAsset(null); }}>Back</Button>
									<Button onClick={() => { setOpenAlert(true); }} type='primary'>Next</Button>
								</div>
							) : null}
						</div>
					) : null}

					{/* <input
							onClick={() => {
								setExitAssignModal(true);
								setOpenModal(false);
							}}
							type="radio"
							name="radio"
							id="exit"
						/>
						<span>Exit</span>

						<input
							className="save-button"
							onClick={() => {
								unassignAsset(selectedAsset._id);
							}}
							type="radio"
							name="radio"
							id="replacement"
						/>
						<span>Replacement</span> */}
					{/* </div> */}
				</div>
			</Modal>
			<Modal
				style={{ animationDuration: '0s' }}
				footer={null}
				onCancel={() => {
					setExitAssignModal(false);
				}}
				open={exitassignModal}
				destroyOnClose={true}
			>
				<div className="exit-modal-container">
					<div className="heading-text">Please mention the date of exit</div>
					{/* <input className="date-unassign" type="date" required /> */}
					<DatePicker
						style={{ width: '200px' }}
						onChange={(date, dateString) => {
							setDateofExit(dateString);
						}}
					/>
					<button
						onClick={() => {
							unassignAsset(selectedAsset._id, dateofExit);
							setExitAssignModal(false);
						}}
						className="button-unassign exit-unassign"
					>
						Unassign
					</button>
				</div>
			</Modal>

			<div className="asset-management-tabs">
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: '#33D7DB',
							colorBgContainer: '#F6F6F6',
							colorBorder: '#FAFAFA',
						},
					}}
				>
					<Tabs
						tabBarStyle={{
							height: '30px',
						}}
						className="asset-tabs"
						centered
						defaultActiveKey="1"
						type="card"
						items={tabsOptions}
					/>
				</ConfigProvider>
			</div>
		</div>
	);
};

const ShowClearImageIcon = ({ clearImg, value, onRemoveImage }) => {
	if (clearImg === value) {
		return <AiOutlineCloseCircle onClick={onRemoveImage} />;
	}
	return null;
};

const AddComment = ({ comment = '', assetId, updateAsset, dispatch, archievedAsset }) => {
	const [showModal, setModalState] = useState(false);
	const [updatedComment, setComment] = useState(comment);
	const [commentEditor, setCommentEditor] = useState(false);
	const { TextArea } = Input;

	useEffect(() => {
		setComment(comment);
	}, [comment]);
	const customStyles = {
		content: {
			top: '50%',
			width: 'auto',
			height: 'auto',
			padding: '0',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-50%, -50%)',
			backgroundColor: '#f8f8f8',
			overflow: 'hidden',
			boxShadow: '12px 12px 8px rgba(85, 85, 85, 0.12)',
			borderRadius: '5px',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			zIndex: 1201,
		},
	};

	const addComment = async () => {
		try {
			const resp = await postApi({ comment: updatedComment, assetId }, '/assetze/asset-update/update-comment');
			if (resp.type === 'success') {
				updateAsset(resp.updatedAsset);
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: 'Successfully Updated Asset Comments',
						timeout: 7000,
					})
				);
				// setModalState(false);
				setCommentEditor(false);
			} else {
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			dispatch(
				showSnack('INVALID_HEALTH', {
					label: 'Something went wrong',
					timeout: 7000,
				})
			);
		}
	};

	return (
		<>
			<div className="comment-div">
				<div className="comment-inner-div">
					<div className="health-comment">Comments</div>
					{commentEditor ? (
						<div className="comment-textarea">
							<TextArea
								onChange={(e) => {
									setComment(e.target.value);
								}}
								value={updatedComment}
								type={'text'}
								rows={3}
								placeholder={'Write An Update'}
							/>
							<div className="flexdiv">
								<div className="note">Note: Provide Device Update if any</div>
								<div className="btns">
									<Button
										style={{ backgroundColor: 'white' }}
										onClick={() => {
											setCommentEditor(false);
											setComment('');
										}}
									>
										Cancel
									</Button>
									<Button onClick={addComment} type={'primary'} className={'update-cmt-btn'}>
										Update Comment
									</Button>
								</div>
							</div>
						</div>
					) : (
						<div className="health-comment-div">{comment ? comment : 'Write An Update'}</div>
					)}
				</div>
				{commentEditor ? (
					<></>
				) : (
					<CheckPermission requiredPermission={COMMENT_EDIT}>
						<div className="edit-btn" onClick={() => setCommentEditor(true)}>
							<EditTwoTone />
						</div>
					</CheckPermission>
				)}
			</div>
		</>
	);
};

const AssetImages = ({ updateAsset, assetId, setAssetImg, assetsImages, AssetImg }) => {
	const [clearImg, setClearImage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showModal, setModalState] = useState(false);
	const hiddenFileInput = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		setClearImage(AssetImg + 1);
	}, [AssetImg]);

	const uploadFile = useCallback(
		async (file) => {
			try {
				if (file.size > 10e6) {
					dispatch(
						showSnack('INVALID_HEALTH', {
							label: 'File Size should be smaller than 10MB',
							timeout: 7000,
						})
					);
				}
				setLoading(true);
				const resp = await postApi(
					{
						filename: file.name,
						mimetype: file.type,
						assetId,
						clearImg: clearImg - 1,
					},
					'/assetze/asset-images/get-upload-url'
				);
				if (resp.type === 'success') {
					await uploadToS3(resp.url, file.type, file);
					const updatedResp = await postApi(
						{ originalUrl: resp.url, assetId, clearImg: clearImg - 1 },
						'/assetze/asset-images/image-upload-done'
					);
					if (updatedResp.type === 'success') {
						updateAsset(updatedResp.assetInfo);
						dispatch(
							showSnack('INVALID_HEALTH', {
								label: 'Successfully Updated Asset Image',
								timeout: 7000,
							})
						);
						setModalState(false);
					}
				}
				setLoading(false);
			} catch (e) {
				dispatch(
					showSnack('FILE_UPLOAD_SUCCESS', {
						label: 'Something Went Wrong Please try after some time',
						timeout: 7000,
					})
				);
			}
		},
		[assetId, dispatch, updateAsset, clearImg]
	);

	const customStyles = {
		content: {
			top: '50%',
			width: 'auto',
			height: 'auto',
			padding: '0',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-50%, -50%)',
			backgroundColor: '#f8f8f8',
			overflow: 'hidden',
			boxShadow: '12px 12px 8px rgba(85, 85, 85, 0.12)',
			borderRadius: '5px',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			zIndex: 1201,
		},
	};
	const onRemoveImage = () => {
		console.log('onRemoveImage');
		console.log(clearImg);
		setModalState(true);
	};
	const handleButtonClick = () => {
		hiddenFileInput.current.click();
	};
	async function onFileChange(event) {
		console.log(event.target.files[0]);
		const file = event.target.files[0];
		await uploadFile(file);
	}
	return (
		<div className="dev-images">
			<Modal
				width={430}
				// contentLabel="Filter Assets"
				onCancel={() => {
					setModalState(false);
				}}
				footer={null}
				// isOpen={showModal}
				open={showModal}
			// style={customStyles}
			>
				<div className="update-asset-image">
					<h3>Update Image</h3>
					<img className="pc-image-2" src={assetsImages && assetsImages[AssetImg]} alt="Asset Image 1" />
					<br />
					<button className="upload-asset-button" onClick={handleButtonClick}>
						Upload New Image
						<input
							accept="image/png, image/gif, image/jpeg"
							style={{ display: 'none' }}
							ref={hiddenFileInput}
							type="file"
							onChange={onFileChange}
						/>
					</button>
					{loading ? <p>Uploding Asset Image</p> : null}
					{/* <button className="upload-asset-button">Upload New Image</button> */}
				</div>
			</Modal>
			{assetsImages &&
				assetsImages.map((el, index) => {
					return (
						<div key={index} className="asset-min-image-container">
							<img
								src={el}
								onClick={() => {
									setAssetImg(index);
									setClearImage(index + 1);
								}}
								alt="Asset Image 1"
							/>
							{index + 1 === clearImg ? (
								<CheckPermission requiredPermission={IMAGE_CREATE}>
									<ShowClearImageIcon
										value={index + 1}
										onRemoveImage={onRemoveImage}
										clearImg={clearImg}
									/>
								</CheckPermission>
							) : null}
						</div>
					);
				})}
		</div>
	);
};

export default TemporaryDrawer;

/**
 * 1. windows keys - entry add dynamic dashes in assets configuration
 * 2. admin category and linking assets with this
 * 3. windows keys - as a seperate asset which needs to be linked to assets
 * 4. location ins admin section
 * 5. email trigger on assigning asset
 */

function ShowLocationInfo({ officeId }) {
	// useEffect(() => {}, [selectedAsset._id]);

	if (!officeId && typeof officeId !== 'object') {
		return null;
	}

	return (
		<div className="asset-department-div" style={{ marginBottom: '10px' }}>
			<div className="asset-department">Location:</div>
			<div className="asset-department-value">{officeId.fullAddres}</div>
		</div>
	);
}

function ShowVendorName({ invoiceId }) {
	if (!invoiceId && typeof invoiceId !== 'object') {
		return null;
	}

	return (
		<div className="health-vendor-div">
			<div className="health-vendor-text">Vendor</div>
			<div className="health-vendor-name">{invoiceId.vendorName}</div>
		</div>
	);
}
