import { getApi, postApi, putApi, patchApi } from '../services/api';
import { showSnack, dismissSnack } from 'react-redux-snackbar';
import LogRocket from 'logrocket';

const { serverUrl } = require('../services/constants');

/*
 * action types
 */

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_MODAL = 'LOGIN_MODAL';
export const SET_ASSETS_RESULTS = 'SET_ASSETS_RESULTS';
export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const LOGOUT_USER = 'LOGOUT_USER';
export const INCREATE_ASSETS_PAGENUMBER = 'INCREATE_ASSETS_PAGENUMBER';
export const REACHED_END_PAGINATION = 'REACHED_END_PAGINATION';
export const ADD_NEW_ASSETS_PAGINATION = 'ADD_NEW_ASSETS_PAGINATION';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_FILTER_TEXT = 'SET_FILTER_TEXT';
export const ADD_FILTER_TABLE = 'ADD_FILTER_TABLE';
export const RESET_ASSETS_TABLE = 'RESET_ASSETS_TABLE';
export const UPDATE_ASSET_INFO = 'UPDATE_ASSET_INFO';
export const UPDATE_COMPANY_ROLES = 'UPDATE_COMPANY_ROLES';
export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO';
export const REMOVE_ASSET = 'REMOVE_ASSET';
export const ADD_NEW_QUESTION = 'ADD_NEW_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const UPDATE_MULTIPLE_CHOICE_QUESTION = 'UPDATE_MULTIPLE_CHOICE_QUESTION';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const UPDATE_UPLOAD = 'UPDATE_UPLOAD';
// export const VENDOR_FORM_DATA = "VENDOR_FORM_DATA";
export const VENDOR_CUSTOM_DATA = 'VENDOR_CUSTOM_DATA';
export const CHANGE_QUESTION_TYPE = 'CHANGE_QUESTION_TYPE';
export const CHANGE_TO_MULTIPLECHOICE_TYPE = 'CHANGE_TO_MULTIPLECHOICE_TYPE';
export const UPDATE_CHOICE = 'UPDATE_CHOICE';
export const ADD_NEW_CHOICE = 'ADD_NEW_CHOICE';
export const VENDOR_FORM_DATA = 'VENDOR_FORM_DATA';
export const HANDLE_INPUT_CHANGES = 'HANDLE_INPUT_CHANGES';
export const QUESTION_TYPE = 'QUESTION_TYPE';
export const ADD_VENDOR_DETAILS = 'ADD_VENDOR_DETAILS';
export const ADD_NEW_SECTION = 'ADD_NEW_SECTION';
export const UPDATE_SECTIONS = 'UPDATE_SECTIONS';

export const ADD_QUESTIONS = 'ADD_QUESTIONS';
export const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS';
export const DELETE_SECTION = 'DELETE_SECTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const MOVE_QUESTION_UP = 'MOVE_QUESTION_UP';
export const MOVE_QUESTION_DOWN = 'MOVE_QUESTION_DOWN';
export const MOVE_SECTION_UP = 'MOVE_SECTION_UP';
export const MOVE_SECTION_DOWN = 'MOVE_SECTION_DOWN';
export const ADD_CHOICES = 'ADD_CHOICES';
export const DELETE_CHOICE = 'DELETE_CHOICE';
export const SAVE_SECTION_ID = 'SAVE_SECTION_ID';
export const EDIT_QUESTION_ORDER = 'EDIT_QUESTION_ORDER';
export const SHOW_TEMPLATE = 'SHOW_TEMPLATE';
export const SHOW_CUSTOM_FIELD = 'SHOW_CUSTOM_FIELD';
export const RESET_FORM = 'RESET_FORM';
export const UPDATE_QUESTION_ANSWER = 'UPDATE_QUESTION_ANSWER';
export const UPDATE_MCQ_ANSWER = 'UPDATE_MCQ_ANSWER';
export const UPDATE_FILE_UPLOAD = 'UPDATE_FILE_UPLOAD';
export const SET_SECTION_ERROR = 'SET_SECTION_ERROR';
export const SET_QUESTION_ERROR = 'SET_QUESTION_ERROR';
export const SET_UPlOAD_ERROR = 'SET_UPLOAD_ERROR';
export const CHECKED_MCQ_ANS = 'CHECKED_MCQ_ANS';
export const SET_ASSET_TABLS_COLUMNS = 'SET_ASSET_TABLS_COLUMNS';
export const SET_TOTAL_ASSET_COUNT = 'SET_TOTAL_ASSET_COUNT';

export const PERMISSIONS_SUCCESSFUl = 'PERMISSIONS_SUCCESSFUl';
export const ROLENAME_SUCCESSFUL = 'ROLENAME_SUCCESSFUL';
/*
 * action creators
 */

export const loginFailed = () => ({ type: LOGIN_FAILED });
export const showLoginModal = () => ({ type: LOGIN_MODAL, payload: true });
export const hideLoginModal = () => ({ type: LOGIN_MODAL, payload: false });
export const logoutUser = () => ({ type: LOGOUT_USER });
export const addColumn = (data) => ({ type: ADD_FILTER_TABLE, payload: data });
export const removeAsset = (data) => ({ type: REMOVE_ASSET, payload: data });
export const setAssetColumns = (data) => ({ type: SET_ASSET_TABLS_COLUMNS, payload: data });

export const verifyToken = (data) => (dispatch) => {
	return postApi({}, '/auth/verify-token')
		.then((response) => {
			if (response.type === 'success') {
				console.log('response', response);
				dispatch({ type: LOGIN_SUCCESS });
				dispatch({ type: SAVE_USER_INFO, payload: response.user });

				LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
					name: response.user.firstName,
					email: response.user.email,
				});

				if (response.company && response.company.roles) {
					dispatch({
						type: UPDATE_COMPANY_ROLES,
						payload: response.company.roles,
					});
				}
				if (response.company && response.company.logo) {
					dispatch({ type: SET_COMPANY_LOGO, payload: response.company.logo });
				}

				getAdminRoles(dispatch);

				return true;
			} else {
				dispatch({ type: LOGIN_FAILED });
				return false;
			}
		})
		.catch((err) => {
			console.warn(err);
			return false;
		});
};

export const getAdminRoles = async (dispatch) => {
	try {
		const response = await getApi('/assetze/roles/get');

		if (response.type === 'success') {
			console.log('permissions', response);

			if (response.roleInfo && response.roleInfo.policies) {
				dispatch({
					type: PERMISSIONS_SUCCESSFUl,
					payload: response.roleInfo.policies,
				});
			}

			if (response.roleInfo && response.roleInfo.roleName) {
				dispatch({
					type: ROLENAME_SUCCESSFUL,
					payload: response.roleInfo.roleName,
				});
			}

			return true;
		}
	} catch (e) {
		return false;
	}
};

export const earlyAccessSignup = (data) => (dispatch) => {
	return postApi(data, '/auth/signup')
		.then((response) => {
			if (response.type === 'success') {
				dispatch(
					showSnack('SIGNUP_SUCCESS', {
						label: 'We have successfully received the request will get back to you.',
						timeout: 7000,
					})
				);
				return true;
			} else {
				dispatch(
					showSnack('SIGNUP_FAILED', {
						label: response.error,
						timeout: 7000,
					})
				);
			}
			return false;
		})
		.catch((err) => console.warn(err));
};

export const loginAction = (data) => (dispatch) => {
	return postApi(data, '/auth/login?requestType=verifyPassword')
		.then((response) => {
			if (response.type === 'success') {
				dispatch({ type: LOGIN_SUCCESS });
				localStorage.setItem('jwt-token', response.token);
				dispatch(verifyToken());
				return true;
			} else {
				dispatch(
					showSnack('LOGIN_ERROR', {
						label: response.error,
						timeout: 7000,
					})
				);
			}
			return false;
		})
		.catch((err) => console.warn(err));
};

export const getAssets = (pageNo, limit) => (dispatch) => {
	return getApi(`/assets/get-assets?pageNumber=${pageNo}&limit=${limit}`)
		.then((resp) => {
			if (resp.type === 'success') {
				dispatch({
					type: SET_ASSETS_RESULTS,
					payload: resp.assets.map(el => ({ ...el, key: el._id })),
					totalAssetsCount: resp.totalAssetsCount * 1,
					limit: resp.limit * 1,
					pageNumber: resp.pageNumber * 1,
				});
			}
		})
		.catch((err) => {
			console.error(err);
		});
};
export const getAssetsPagination = (data) => (dispatch, getState) => {
	const pageNumber = getState().appreducer.assetsPageNumber;
	const filterText = getState().appreducer.filterText;
	if (filterText && filterText.length > 0) {
		return null;
	}
	return getApi(`/assets/get-assets?pageNumber=${pageNumber + 1}`)
		.then((resp) => {
			if (resp.type === 'success') {
				if (resp.assets.length > 0) {
					if (resp.assets.length <= 10) {
						dispatch({ type: INCREATE_ASSETS_PAGENUMBER });
					}

					dispatch({ type: ADD_NEW_ASSETS_PAGINATION, payload: resp.assets });
				}
			}
		})
		.catch((err) => {
			console.error(err);
		});
};
