import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';

import AssetsTable from '../../components/AssetsTAble';
import { FilterAssets } from '../../components/FilterAssets';
import FooterDashboard from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import useDebounce from '../../hooks/useDebounce';
import searchReducer from '../../redux/searchReducer';
import { getApi } from '../../services/api';
import AssetsTopHeader from '../IncidentsScreen/AssetsTopHeader';
import './index.css';
import { fetchFilterData, showFilterData } from '../../components/FilterAssets/fetchFilterData';
import { ShowFilterView } from '../../components/FilterAssets/ShowFilterView';
import { addExtraColums } from '../../redux/addExtraColums';
import { escapeRegExp } from '../../utils/sanitizeFileName';

const AssetsManagment = function () {
	const [openModal, setModalState] = useState(false);
	const [state, searchDispatch] = useReducer(searchReducer, {
		isSearched: false,
		searchText: '',
		searchAssets: [],
		filterInfo: [],
	});
	const debouncedSearchTerm = useDebounce(state.searchText, 500);
	const filterText = escapeRegExp(debouncedSearchTerm);
	const dispatch = useDispatch();
	useEffect(() => {
		if (filterText.length === 0) {
			return searchDispatch({ type: 'saveSearchResult', payload: [] });
		}
		if (filterText) {
			const fetchAssets = async () => {
				if (filterText.length > 0) {
					const url = `/assets/get-assets?filterText=${filterText}`;
					const resp = await getApi(url);
					if (resp.type === 'success') {
						if (resp.assets.length === 0) {
							return dispatch(
								showSnack('ASSET_NOT_FOUND', {
									label: 'Asset Not Found',
									timeout: 7000,
								})
							);
						}
						searchDispatch({
							type: 'saveSearchResult',
							payload: resp.assets.map((el) => ({ ...el, key: el._id })),
						});
						dispatch({ type: 'SET_TOTAL_ASSET_COUNT', payload: resp.totalAssetsCount });
					} else {
						dispatch(
							showSnack('NOT_ABLE_TO_FETCH_ASSETS', {
								label: resp.message ? resp.message : resp.error,
								timeout: 7000,
							})
						);
					}
				}
			};

			fetchAssets();
		} else {
			console.log('searchTermnotfound');
		}
	}, [filterText, searchDispatch, dispatch]);
	const onFilterTextChange = (e) => {
		console.log(e.target.value, 'e.target.value');
		searchDispatch({ type: 'updatedSearchText', payload: e.target.value });
		//setFilterText(e.target.value);
	};
	const removeAsset = (assetId) => {
		searchDispatch({ type: 'removeAsset', payload: assetId });
	};
	const saveFilterInfo = async (filterInfo) => {
		console.log('savefilterinfo 1212', filterInfo);
		searchDispatch({ type: 'saveFilterInfo', payload: filterInfo });

		if (filterInfo && filterInfo.length === 0) {
			searchDispatch({ type: 'saveSearchResult', payload: [] });
			setModalState(false);
			return;
		}

		const assetList = await showFilterData(filterInfo, dispatch, 'show');
		if (assetList && assetList.length > 0) {
			searchDispatch({ type: 'saveSearchResult', payload: assetList });
			setModalState(false);
		}
	};

	const deleteFilter = () => {
		console.log('deleteFilter');
	};

	return (
		<>
			<div className="dashboard-container">
				<HeaderComponent />
				<AssetsTopHeader />
				<FilterAssets
					openModal={openModal}
					setModalState={setModalState}
					saveFilterInfo={saveFilterInfo}
					searchText={state.searchText}
					onFilterTextChange={onFilterTextChange}
					filterView={
						<ShowFilterView
							extraClass={'dashboard-filter'}
							allFilterInfo={state.filterInfo}
							deleteFilter={deleteFilter}
							openModal={openModal}
							setModalState={setModalState}
						/>
					}
				/>
				<AssetsTable
					searchAssets={state.searchAssets}
					removeAssetInSearch={removeAsset}
					filterText={filterText}
					searchDispatch={searchDispatch}
				/>
				<FooterDashboard />
			</div>
		</>
	);
};

export default AssetsManagment;
