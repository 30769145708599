import React, { useEffect, useState } from 'react'
import HeaderComponent from '../../components/Header';
import './index.css'
import { Icon1, Icon2, Icon3, Icon4, Icon5 } from '../../images/svgs/IncidentManagementSvg';
import { Chart } from 'react-google-charts';
import { Button, Select } from 'antd';
import { getLocationsDropdown } from '../../components/Dashboard/utils';
import { useNavigate } from 'react-router-dom';


const IncidentPieChart = function () {

    const data = [
        ['Asset Type', 'Total'],
        ['a', 30],
        ['b', 50],
        ['c', 20],
    ];

    const options = {
        legend: 'none',
        pieHole: 0.7,
        is3D: false,
        chartArea: { width: '95%', height: '95%' },
    };

    return <Chart chartType="PieChart" data={data} options={options} width={150} height={150} />;
};


const IncidentManagement = () => {
    const [selectedOfficeId, setSelectedOfficeId] = useState('');
    const [officeLocation, setOfficeLocations] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getLocs = async () => {
            const locs = await getLocationsDropdown();
            setOfficeLocations(locs);
        };
        getLocs();
    }, []);


    const handleChange = (value) => {
        console.log(`selected ${value}`);
        if (value) {
            setSelectedOfficeId(value);
        } else {
            setSelectedOfficeId('');
        }
    };

    const officeLocs = officeLocation.map((el) => {
        return {
            label: el.branchId,
            value: el._id,
        };
    });


    return (
        <div className='im-div'>
            <HeaderComponent />
            <div className='im-header'>
                <div className='heading'>Incident Management</div>
                <div>
                    <Select
                        value={selectedOfficeId}
                        style={{
                            width: 200,
                        }}
                        allowClear
                        options={officeLocs}
                        onChange={handleChange}
                        placeholder="select organisation branch"
                    />
                    <Button style={{ marginLeft: "20px" }} onClick={() => { navigate('/create-ticket') }} type='primary'>Create Ticket</Button>
                </div>
            </div>
            <div className='im-maindiv'>
                <div className='upperdiv'>
                    <div className='smalldiv'>
                        <Icon1 />
                        <div className='value'>3</div>
                        <div className='text'>Pending Tickets</div>
                    </div>
                    <div className='smalldiv'>
                        <Icon2 />
                        <div className='value'>3</div>
                        <div className='text'>Recents Tickets</div>
                    </div>
                    <div className='smalldiv'>
                        <Icon3 />
                        <div className='value'>3</div>
                        <div className='text'>In-Progress Tickets</div>
                    </div>
                    <div className='smalldiv'>
                        <Icon4 />
                        <div className='value'>3</div>
                        <div className='text'>Tickets This Week</div>
                    </div>
                    <div className='smalldiv'>
                        <Icon5 />
                        <div className='value'>3</div>
                        <div className='text'>Resolved Tickets</div>
                    </div>
                </div>
                <div className='lowerdiv'>
                    <div className='div1'>
                        <div className='week-div'>
                            <div className='im-heading'>This Week</div>
                            <div className='flexdiv'>
                                <div className='leftdiv'>
                                    <div className='list'>
                                        <div className='colordiv'></div>
                                        <div>Resolved</div>
                                    </div>
                                    <div className='list'>
                                        <div className='colordiv'></div>
                                        <div>In-Progress</div>
                                    </div>
                                    <div className='list'>
                                        <div className='colordiv'></div>
                                        <div>Pending</div>
                                    </div>
                                </div>
                                <div className='chartdiv'>
                                    <IncidentPieChart />
                                </div>
                            </div>
                        </div>

                        <div className='leaderboard-div'>
                            <div className='im-heading'>Leaderboard</div>
                            <div className='headingdiv'>
                                <div className='tableheading'>Admin</div>
                                <div className='tableheading'>Tickets Resolved</div>
                                <div className='tableheading'>Performance</div>
                            </div>
                            <div className='datadiv'>
                                <div className='text'>Sumanth</div>
                                <div className='text'>10</div>
                                <div className='percentage'>12%</div>
                            </div>
                            <div className='datadiv'>
                                <div className='text'>Sumanth</div>
                                <div className='text'>10</div>
                                <div className='percentage'>12%</div>
                            </div>


                        </div>
                    </div>
                    <div className='div2'>
                        <div className='ticket-board-div'>
                            <div className='im-heading'>Ticket Board</div>
                            <div className='flexdiv'>
                                <div className='text'>John Doe</div>
                                <div className='text'>Hardware Issue</div>
                                <div style={{ textAlign: "right", color: "#40DD82" }} className='text'>1 hr 29 mins</div>
                            </div>
                            <div className='flexdiv'>
                                <div className='text'>John</div>
                                <div className='text'>Hardware Issue</div>
                                <div style={{ textAlign: "right", color: "#40DD82" }} className='text'>1 hr 29 mins</div>
                            </div>

                        </div>
                        <div className='weekly-chart-div'>
                            <div className='im-heading'>Weekly Charts</div>
                        </div>

                    </div>
                    <div className='div3'>
                        <div className='im-heading'>Tickets on Priority</div>
                        <div className='flexdiv'>
                            <div className='text'>John doe</div>
                            <div className='text'>Medium</div>
                        </div>
                        <div className='flexdiv'>
                            <div className='text'>Alex</div>
                            <div className='text'>High</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default IncidentManagement