import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './index.css';
import { AiFillStar, AiOutlineDelete } from 'react-icons/ai';
import { BiLinkExternal, BiArchiveIn } from 'react-icons/bi';
import { FiEdit2 } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
// import Drawer from '@mui/material/Drawer';
import { Table } from 'antd';

import TemporaryDrawer from '../../screens/DeviceDetails';
import { showSnack } from 'react-redux-snackbar';
import _ from 'lodash';
import {
	getAssets,
	getAssetsPagination,
	removeAsset,
	RESET_ASSETS_TABLE,
	UPDATE_ASSET_INFO,
} from '../../redux/actions';
import { differenceInDays } from 'date-fns';
// import Modal from 'react-modal';
import { deleteApi, getApi, postApi } from '../../services/api';
import { Drawer, Modal } from 'antd';
import {
	ASSET_DELETE,
	ASSET_EDIT,
	ASSET_VIEW,
	CheckPermission,
	CheckPermissionCondition,
} from '../../utils/Permissions';

function getValueByPath(obj, path) {
	// Split the key path by '.'
	const keys = path.split('.');

	// Iterate over the keys to access the nested value
	let result = obj;

	console.log(result, path);
	for (const key of keys) {
		if (result && result.hasOwnProperty(key)) {
			result = result[key];
		} else {
			return undefined; // Return undefined if key path is invalid
		}
	}

	console.log(result, path);

	return result;
}

const AssetsTable = function ({ searchAssets, removeAssetInSearch, filterText, searchDispatch }) {
	const listInnerRef = useRef();
	// const [columnList, setDefaultColumns] = useState(InitialColumns);
	// const [pageNumber, setPageNumber] = useState(0);
	const assets = useSelector((state) => state.appreducer.assets);
	const pageNumber = useSelector((state) => state.appreducer.assetsTablePageNum);
	const resetAssetsTable = useSelector((state) => state.appreducer.resetAssetsTable);
	const arrayOfChosenOptions = useSelector((state) => state.appreducer.assetsTableColumns);
	const totalAssetsCount = useSelector((state) => state.appreducer.totalAssetsCount);
	const assetsTableLimit = useSelector((state) => state.appreducer.assetsTableLimit);
	const [selectedAsset, setSelectedAsset] = useState({});
	const [checkboxAssets, setCheckboxAssets] = useState([]);
	const dispatch = useDispatch();
	const [state, setState] = React.useState({
		right: false,
	});

	useEffect(() => {
		dispatch(getAssets(pageNumber, assetsTableLimit));
	}, [pageNumber, assetsTableLimit, dispatch]);

	useEffect(() => {
		if (resetAssetsTable !== '123') {
			// setPageNumber(0);
			dispatch(getAssets(0, assetsTableLimit));
			dispatch({ type: RESET_ASSETS_TABLE, payload: '123' });
		}
	}, [dispatch, pageNumber, resetAssetsTable]);

	const toggleDrawer = (toggleState) => {
		setState({ ...state, right: toggleState });
	};

	const removeAssetCmp = (assetId) => {
		if (searchAssets.length > 0) {
			removeAssetInSearch(assetId);
		} else {
			dispatch(removeAsset(assetId));
		}
	};

	const handleTableChange = (pagination, filters, sorter) => {
		dispatch(getAssets(pagination.current - 1, pagination.pageSize));
	};

	const handlePagination = (pagination) => {
		if (filterText) {
			const fetchAssets = async () => {
				if (filterText.length > 0) {
					const url = `/assets/get-assets?filterText=${filterText}&page=${pagination.current}&limit=${pagination.pageSize}`;
					const resp = await getApi(url);
					if (resp.type === 'success') {
						if (resp.assets.length === 0) {
							return dispatch(
								showSnack('ASSET_NOT_FOUND', {
									label: 'Asset Not Found',
									timeout: 7000,
								})
							);
						}
						searchDispatch({ type: 'saveSearchResult', payload: resp.assets });
						dispatch({ type: 'SET_TOTAL_ASSET_COUNT', payload: resp.totalAssetsCount });
					} else {
						dispatch(
							showSnack('NOT_ABLE_TO_FETCH_ASSETS', {
								label: resp.message ? resp.message : resp.error,
								timeout: 7000,
							})
						);
					}
				}
			};

			fetchAssets();
		}
		console.log(pagination);
	};

	const assetsInTable = searchAssets && searchAssets.length > 0 ? searchAssets : assets;

	const health = Array(5)
		.fill()
		.map((v, i) => i);

	let InitialColumns = [
		{ title: 'Sl.No', width: 100, dataIndex: 'sequence', key: 'sequence', fixed: true },
		{ title: 'Asset Type', dataIndex: 'type', key: 'assetType', width: 100 },
		{ title: 'Asset', dataIndex: 'name', key: 'name', width: 150 },
		{ title: 'Asset ID', dataIndex: 'id', key: 'id', width: 100 },
		{
			title: 'Name',
			dataIndex: 'employee',
			key: 'employee',
			render: (employee) => <div>{employee ? employee.name : 'NA'}</div>,
			width: 150,
		},
		{ title: 'Department', dataIndex: 'department', key: 'department', width: 150 },
		{ title: 'Serial Number', dataIndex: 'serialNumber', key: 'serialNumber', width: 150 },
		{
			title: 'Actions',
			dataIndex: 'actions',
			width: 100,
			key: 'actions',
			render: (_, el) => (
				<div className="table-action-assets">
					<BiLinkExternal
						color={'#666666'}
						onClick={() => {
							setSelectedAsset(el);
							toggleDrawer(true);
						}}
					/>
					<CheckPermission requiredPermission={ASSET_EDIT}>
						<Link to={`edit/${el._id}`}>Edit</Link>
					</CheckPermission>
					<CheckPermission requiredPermission={ASSET_DELETE}>
						<ArchieveAsset assetId={el._id} removeAsset={removeAssetCmp} />
					</CheckPermission>
				</div>
			),
			// fixed: 'right',
		},
	];
	const updatedRows = [];
	for (let x = 0; x < arrayOfChosenOptions.length; x++) {
		const eachRecord = arrayOfChosenOptions[x];

		if (eachRecord.checked) {
			if (eachRecord.mapId === 'health') {
				updatedRows.push({
					title: eachRecord.name,
					dataIndex: eachRecord.mapId,
					key: eachRecord.mapId,
					width: 120,
					render: (_, el) => {
						return health.map((index) => {
							const color = index < el.healthScore ? '#FDD305' : '#CCCCCC';
							return <AiFillStar key={index} color={color} />;
						});
					},
				});
			} else if (eachRecord.mapId === 'dateOfAssign') {
				updatedRows.push({
					title: eachRecord.name,
					dataIndex: eachRecord.mapId,
					key: eachRecord.mapId,
					width: 100,
				});
			} else if (eachRecord.mapId === 'warrantyRemaining') {
				updatedRows.push({
					title: eachRecord.name,
					dataIndex: eachRecord.mapId,
					key: eachRecord.mapId,
					width: 100,
					render: (_, el) => {
						const warrantyRemaining = differenceInDays(new Date(el.warrantyExpiryDate), new Date());
						if (warrantyRemaining > 0)
							return <span>{differenceInDays(new Date(el.warrantyExpiryDate), new Date())} Days</span>;
						else return <span>Product Out of Warranty</span>;
					},
				});
			} else if (eachRecord.custom) {
				updatedRows.push({
					title: eachRecord.name,
					dataIndex: eachRecord.mapId,
					key: eachRecord.mapId,
					width: 100,
					render: (_, el) => {
						return <span>{el.cs ? el.cs[eachRecord.mapId] : null}</span>;
					},
				});
			} else {
				updatedRows.push({
					title: eachRecord.name,
					dataIndex: eachRecord.mapId,
					key: eachRecord.mapId,
					width: 100,
					render: (_, el) => {
						return <span>{getValueByPath(el, eachRecord.mapId)}</span>;
					},
				});
			}
		}
	}
	InitialColumns = InitialColumns.concat(updatedRows);

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRowKeys, selectedRows);
			setCheckboxAssets(selectedRowKeys);
		},
	};

	return (
		<div ref={listInnerRef} className="assets-table-container">
			<div className="toggle-drawer">
				<Drawer
					style={{ backgroundColor: '#FAFAFA' }}
					destroyOnClose={true}
					width={window.innerWidth > 600 ? 1047 : 378}
					// width={1047}
					// height={671}
					anchor={'right'}
					open={state['right']}
					onClose={() => toggleDrawer(false)}
				>
					<TemporaryDrawer
						selectedAsset={selectedAsset}
						updateAsset={(newAssetInfo) => {
							setSelectedAsset(newAssetInfo);
							dispatch({ type: UPDATE_ASSET_INFO, payload: newAssetInfo });
						}}
					/>
				</Drawer>
			</div>
			{CheckPermissionCondition(ASSET_VIEW) ? (
				<>
					{searchAssets.length > 0 ? (
						<div className="table-design-1">
							<Table
								columns={InitialColumns}
								scroll={{
									x: 1300,
								}}
								// pagination={{
								// 	total: totalAssetsCount,
								// 	current: pageNumber + 1,
								// 	pageSize: assetsTableLimit,
								// 	pageSizeOptions: [10, 20, 50],
								// }}
								// rowSelection={{
								// 	type: 'checkbox',
								// 	...rowSelection,
								// 	preserveSelectedRowKeys: true
								// }}
								dataSource={assetsInTable}
								onChange={handlePagination}
								bordered
							/>
						</div>
					) : (
						<div className="table-design-1">
							<Table
								columns={InitialColumns}
								scroll={{
									x: 1300,
								}}
								// rowSelection={{
								// 	type: 'checkbox',
								// 	...rowSelection,
								// 	preserveSelectedRowKeys: true,
								// }}
								dataSource={assetsInTable}
								bordered
								pagination={{
									total: totalAssetsCount,
									current: pageNumber + 1,
									pageSize: assetsTableLimit,
									pageSizeOptions: [10, 20, 50],
								}}
								onChange={handleTableChange}
							/>
						</div>
					)}
				</>
			) : (
				<div style={{ textAlign: 'center' }}>You don't have permission to view this page</div>
			)}

			{false ? (
				<table ref={listInnerRef} className="assetsTable" id="myTable">
					<thead>
						<tr>
							<th>Asset Type</th>
							<th>Asset</th>
							<th>Asset ID</th>
							<th>Name</th>
							{/* <th>Employee ID</th> */}
							<th>Department</th>
							<th>Serial Number</th>
							<th>Actions</th>
							{arrayOfChosenOptions
								.filter((el) => el.checked)
								.map((chosenColumn, index) => {
									return <th key={index}>{chosenColumn.name}</th>;
								})}
						</tr>
					</thead>
					<tbody>
						{assetsInTable.map((el) => {
							const arrayCpu = [];
							const arrayRam = [];
							const arrayHdd = [];
							const confs = el.configuration.concat(el.custom);
							confs.map((el) => {
								const cpuObject = el.key.includes('cpu');
								const ramObject = el.key.includes('ram');
								const hddObject = el.key.includes('hdd');
								if (cpuObject) arrayCpu.push(el.value);
								if (ramObject) arrayRam.push(el.value);
								if (hddObject) arrayHdd.push(el.value);
							});

							const health = Array(5)
								.fill()
								.map((v, i) => i);
							return (
								<tr key={el._id}>
									<td>{el.type}</td>
									<td>{el.name}</td>
									<td>{el.id}</td>
									<td>{el.employee && el.employee.name}</td>
									{/* <td>{el.employee && el.employee.id}</td> */}
									<td>{el.department}</td>
									<td>{el.serialNumber}</td>

									<td className="each-assets-action">
										<BiLinkExternal
											color={'#666666'}
											onClick={() => {
												setSelectedAsset(el);
												toggleDrawer(true);
											}}
										/>
										<Link to={`edit/${el._id}`}>Edit</Link>
										<ArchieveAsset assetId={el._id} removeAsset={removeAssetCmp} />
									</td>
									{arrayOfChosenOptions
										.filter((el) => el.checked)
										.map((chosenColumn, index) => {
											if (chosenColumn.mapId === 'dateOfAssign')
												return <td key={index}>{el.employee && el.employee.dateOfAssign}</td>;
											if (chosenColumn.mapId === 'health') {
												return (
													<td key={index}>
														{health.map((index) => {
															const color =
																index < el.healthScore ? '#FDD305' : '#CCCCCC';
															return <AiFillStar key={index} color={color} />;
														})}{' '}
													</td>
												);
											}
											if (chosenColumn.mapId === 'warrantyRemaining') {
												const warrantyRemaining = differenceInDays(
													new Date(el.warrantyExpiryDate),
													new Date()
												);
												if (warrantyRemaining > 0)
													return (
														<td>
															{differenceInDays(
																new Date(el.warrantyExpiryDate),
																new Date()
															)}{' '}
															Days
														</td>
													);
												else return <td>Product Out of Warranty</td>;
											}
											if (chosenColumn.custom && el.cs) {
												return <td>{el.cs[chosenColumn.mapId]}</td>;
											}
											return <td>{el[chosenColumn.mapId]}</td>;
										})}
								</tr>
							);
						})}
					</tbody>
				</table>
			) : null}
		</div>
	);
};

export default AssetsTable;

const capitalKeys = ['cpu', 'ram', 'hdd', 'ddr4', 'ddr3'];
function convertCamelToTitle(text) {
	return text;
	console.log(text, 'text');
	const result = text.replace(/([A-Z])/g, ' $1');
	const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
	console.log(finalResult, 'finalResult');
	return finalResult;
}
function convertFieldToCaps(key) {
	if (key) {
		for (let x = 0; x < capitalKeys.length; x++) {
			if (key.toLowerCase().includes(capitalKeys[x])) {
				const regEx = new RegExp(capitalKeys[x], 'ig');
				return key.replace(regEx, capitalKeys[x].toUpperCase());
			}
		}
	}
	return key;
}

const EachCustomInput = ({ keyText = '', value }) => {
	let key = keyText.replace(/[^a-zA-Z ]/g, ' ');
	key = convertCamelToTitle(key);
	key = convertFieldToCaps(key);
	return (
		<div className="each-custom-field">
			<div className="key">{key}</div>
			<div className="value">{value ? value : 'NA'}</div>
		</div>
	);
};

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0px',
		boxShadow: '0 0 5px #7f7f7f',
	},
	overlay: {
		backgroundColor: '#4e4e4e65',
	},
};

const ArchieveAsset = function ({ assetId, removeAsset }) {
	const [showModal, toggleModal] = useState(false);
	const dispatch = useDispatch();
	const archieveAsset = async function () {
		try {
			const resp = await deleteApi('/assetze/asset-update', { assetId });
			if (resp.type === 'success') {
				removeAsset(assetId);
				toggleModal(false);
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: 'Successfully Archieved the Asset',
						timeout: 7000,
					})
				);
			} else {
				console.error(resp);
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<>
			<BiArchiveIn
				onClick={() => {
					toggleModal(true);
				}}
				style={{ marginLeft: '10px' }}
				color={'#fa5c5c'}
			/>
			<Modal
				open={showModal}
				onCancel={() => {
					toggleModal(false);
				}}
				title="Archive Asset"
				onOk={() => archieveAsset()}
				okText="Archive"
				cancelText="Cancel"
				okButtonProps={{ danger: true }}
				// footer={false}
				// style={customStyles}
				// contentLabel="Write Post"
				// ariaHideApp={false}
			>
				<div>
					<div>Are you sure you want to archive this asset?</div>
					{/* <div className="asset-actions">
						<button onClick={() => toggleModal(false)} className="negative">
							Cancel
						</button>
						<button onClick={() => archieveAsset()} className="positive">
							Archieve
						</button>
					</div> */}
				</div>
			</Modal>
		</>
	);
};
