import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';
import ReactGA from 'react-ga4';

import LandingScreen from './screens/LandingScreen';
import DashboardScreen from './screens/Dashboard';
import AccountSettings from './screens/AccountSettings';
import { MaserAssetTagSetup } from './screens/AccountSettings/MaserAssetTagSetup';
import AssetsManagment from './screens/AssetsManagment';
import NewAssets from './screens/AssetsManagment/NewAssets';
import TermsScreen from './screens/TermsScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyToken } from './redux/actions';
import PricingScreen from './screens/PricingScreen';
import FAQScreen from './screens/FAQScreen';
import RunDiagnosticScreen from './screens/RunDiagnsotics';
import RunningDiagnosticsScreen from './screens/RuningDiagnostic';
import DiagnosticResultsScreen from './screens/DiagnosticResults';
import PageNotFoundScreen from './screens/PageNotFoundScreen';
import Middleware from './components/Middleware';
import IncideneManagment from './components/IncideneManagment';
import AssetManagment from './components/AssetManagment';
import ReportingScreen from './screens/ReportingScreen';
import DiagnosticToolScren from './screens/DiagnosticToolScreen';
import PartnerScreen from './screens/PartnerScreen';
import ProductScreen from './screens/ProductsScreen';
import IncidentsScreen from './screens/IncidentsScreen';

import ShopNow from './screens/ShopNow';
import ShoppingCart from './screens/ShoppingCart';
import ConfirmOrder from './components/ConfirmOrder';
import PaymentFailed from './screens/FailedPayment';
import ConfirmShip from './components/ConfirmShip';
import GetStarted from './screens/IT-Tracking/GetStarted';
import ITRegister from './screens/IT-Tracking/Register';
import ITLogin from './screens/IT-Tracking/Login';
import MyDevices from './screens/IT-Tracking/MyDevices';
import ServiceRequest from './screens/IT-Tracking/ServiceRequest';
import RequestStatus from './screens/IT-Tracking/RequestStatus';
import Feedback from './screens/IT-Tracking/Feedback';
import DashboardIT from './screens/IT-Tracking/Dashboard';
import EditAssetScreen from './screens/EditAssetScreen';
import ContactUsScreen from './screens/ContactUsScreen';
import RefundPolicy from './screens/RefundPolicy';
import AboutUsScreen from './screens/AboutUs';
import AssetDashboard from './components/Dashboard';
import InStockScreen from './components/InStock';
import NewSignOff from './screens/AccountSettings/NewSignOff';
import DepartmentSignOff from './screens/AccountSettings/DepartmentSignOff';
import SignOffSuccess from './screens/AccountSettings/SignOffSuccess';
import ExitResponses from './screens/AccountSettings/ExitResponses';
import ThanksForSigning from './screens/AccountSettings/ThanksForSigning';
import AuthoritySignScreen from './screens/AccountSettings/AuthoritySignScreen';
import IdfcConnect from './screens/IdfcConnect';
import ActivityInfo from './screens/ActivityInfo';
import ArchieveStockScreen from './components/ArchieveAsset';
import NewLandingScreen from './screens/NewLandingScreen';
import CompanyBranch from './screens/CompanyBranch';
import VendorForm from './components/VendorForm';
import VendorRegForm from './screens/vendors/VendorRegForm';
import CertificationForm from './components/VendorForm/CertificationForm';
import DefaultQuestions from './components/VendorForm/DefaultQuestions';
import ResponseForm from './components/VendorForm/ResponseForm';
import VendorContent from './screens/VendorList';
// import Survey from './screens/Survey';
import ExitEmployee from './screens/ExitEmployee';
import NewLandingDesign from './screens/NewLandingDesign';
import { RegisterScreen } from './components/EarlyAccess';
import { RegisterScreenNew } from './components/EarlyAccessNew';
import { CreatePO } from './screens/AccountSettings/CreatePO';
import JobsInfo from './screens/JobsInfo';
import DownloadAgent from './screens/DownloadAgent';
import ScannerLogin from './screens/Scanner/ScannerLogin';
import Scanner from './screens/Scanner/QrReader.js';
import AssetDetails from './screens/Scanner/AssetDetails/index.js';
import ContactUsPage from './screens/ContactUsPage/index.js';
import Ewaste from './screens/Ewaste/index.js';
import IncidentManagement from './screens/IncidentManagement/index.js';
import CreateTicket from './screens/CreateTicket/index.js';
import DisposeAsset from './screens/DisposeAsset/index.js';

function initialiseAnalytics() {
	ReactGA.initialize('G-E8WX8GX707');
	// ReactGA.initialize('GTM-53JTT79');
}

function usePageTracking() {
	const location = useLocation();
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		initialiseAnalytics();
		setInitialized(true);
	}, []);

	useEffect(() => {
		if (initialized) {
			ReactGA.send({
				hitType: 'pageview',
				page: location.pathname + location.search,
			});
		}
	}, [initialized, location]);
}
function RequireAuth() {
	const loggedIn = useSelector((state) => state.appreducer.loggedIn);
	let location = useLocation();

	if (!loggedIn) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/" state={{ from: location }} />;
	}
	return <Outlet />;
}

function App() {
	const loggedIn = useSelector((state) => state.appreducer.loggedIn);
	const initialLoading = useSelector((state) => state.appreducer.initialLoading);
	const dispatch = useDispatch();
	usePageTracking();
	useEffect(() => {
		dispatch(verifyToken());
	}, []);

	if (initialLoading) {
		return (
			<>
				<Middleware />
				<Routes>
					<Route path="/new-landing" element={<NewLandingDesign />} />
					{/* <Route path="/" element={<LandingScreen />} /> */}
					<Route path="/" element={<NewLandingDesign />} />
					<Route path="/terms" element={<TermsScreen />} />
					<Route path="/privacy" element={<PrivacyScreen />} />
					<Route path="/faqs" element={<FAQScreen />} />
					<Route path="/incideneManagment" element={<IncideneManagment />} />
					<Route path="/terms" element={<TermsScreen />} />
					<Route path="/privacy" element={<PrivacyScreen />} />
					<Route path="/incideneManagment" element={<IncideneManagment />} />
					<Route path="/assetManagment" element={<AssetManagment />} />
					<Route path="/reporting" element={<ReportingScreen />} />
					<Route path="/diagnosticTool" element={<DiagnosticToolScren />} />
					<Route path="/partner" element={<PartnerScreen />} />
					{/* <Route path="/pricing" element={<PricingScreen />} /> */}
					<Route path="/faqs" element={<FAQScreen />} />
					<Route path="/contact" element={<ContactUsPage />} />
					<Route path="/contact-us" element={<ContactUsPage />} />
					<Route path="/payment-failed" element={<PaymentFailed />} />
					<Route path="/confirm-shipping" element={<ConfirmShip />} />
					<Route path="/get-started" element={<GetStarted />} />
					<Route path="/register" element={<ITRegister />} />
					<Route path="/login" element={<ITLogin />} />
					<Route path="/refund-policy" element={<RefundPolicy />} />
					<Route path="/about-us" element={<AboutUsScreen />} />
					<Route path="/idfc-connect" element={<IdfcConnect />} />
					<Route path="/register-assetze" element={<RegisterScreenNew />} />
					<Route path="/register-init" element={<RegisterScreen />} />
					<Route path="/download-agent" element={<DownloadAgent />} />
					<Route path="/scanner/login" element={<ScannerLogin />} />
					<Route path="/e-waste" element={<Ewaste />} />
					{/* <Route path="*" element={<PageNotFoundScreen />} /> */}
					{/* <Route path="/survey" element={<Survey />} /> */}
				</Routes>
			</>
		);
	}
	return (
		<>
			<Middleware />
			<Routes>
				{/* <Route path="/" element={<LandingScreen />} /> */}
				<Route path="/" element={<NewLandingDesign />} />
				<Route path="/new-landing" element={<NewLandingDesign />} />

				<Route element={<RequireAuth />}>
					<Route path="/dashboard-old" element={<DashboardScreen />} />
					<Route path="/account-settings" element={<AccountSettings />} />
					<Route path="/company-branches" element={<CompanyBranch />} />
					<Route path="/vendor-list" element={<VendorContent />} />
					<Route path="/vendor-registration" element={<VendorRegForm />} />
					<Route path="/vendor-form/:token" element={<VendorForm />} />

					<Route path="/certification-form/:vendorId" element={<CertificationForm />} />
					<Route path="/responseSubmitted" element={<ResponseForm />} />

					<Route path="/default-form" element={<DefaultQuestions />} />
					<Route path="/assets" element={<AssetsManagment />} />
					<Route path="/assets/edit/:assetId" element={<EditAssetScreen />} />
					<Route path="/assets/new" element={<NewAssets />} />
					<Route path="/diagnostics" element={<RunDiagnosticScreen />} />
					<Route path="/diagnostics/running" element={<RunningDiagnosticsScreen />} />
					<Route path="/diagnostics/results" element={<DiagnosticResultsScreen />} />
					<Route path="/incidents" element={<IncidentsScreen />} />
					<Route path="/products" element={<ProductScreen />} />
					<Route path="/products/shop" element={<ShopNow />} />
					<Route path="/products/shopping-cart" element={<ShoppingCart />} />
					<Route path="/confirm-order" element={<ConfirmOrder />} />
					<Route path="/devices" element={<MyDevices />} />
					<Route path="/service-request" element={<ServiceRequest />} />
					<Route path="/request-status" element={<RequestStatus />} />
					<Route path="/feedback" element={<Feedback />} />
					<Route path="/dashboard/it-tracking" element={<DashboardIT />} />
					<Route path="/dashboard" element={<AssetDashboard />} />
					<Route path="/in-stock" element={<InStockScreen />} />
					<Route path="/new-sign-off" element={<NewSignOff />} />
					<Route path="/department-sign-off" element={<DepartmentSignOff />} />
					<Route path="/sign-off-suceess" element={<SignOffSuccess />} />
					<Route path="/exit-responses" element={<ExitResponses />} />
					<Route path="/thanks-for-signing" element={<ThanksForSigning />} />
					<Route path="/authority-sign" element={<AuthoritySignScreen />} />
					<Route path="/activity-info" element={<ActivityInfo />} />
					<Route path="/jobs-info" element={<JobsInfo />} />
					<Route path="/archieve-list" element={<ArchieveStockScreen />} />
					<Route path="/exit-employees/:employeeId" element={<ExitEmployee />} />
					<Route path="/account-settings/:view" element={<MaserAssetTagSetup />} />
					<Route path="/account-settings/:view/tag-setup" element={<MaserAssetTagSetup />} />
					<Route path="/account-settings/:view/create-po" element={<CreatePO />} />
					<Route path="/scanner/qr-reader" element={<Scanner />} />
					<Route path="/scanner/asset/:id" element={<AssetDetails />} />
					<Route path="/incident-management" element={<IncidentManagement />} />
					<Route path="/create-ticket" element={<CreateTicket />} />
					<Route path="/dispose-asset" element={<DisposeAsset />} />
				</Route>

				<Route path="/e-waste" element={<Ewaste />} />
				<Route path="/contact-us" element={<ContactUsPage />} />
				<Route path="/scanner/login" element={<ScannerLogin />} />
				<Route path="/terms" element={<TermsScreen />} />
				<Route path="/privacy" element={<PrivacyScreen />} />
				<Route path="/incideneManagment" element={<IncideneManagment />} />
				<Route path="/assetManagment" element={<AssetManagment />} />
				<Route path="/reporting" element={<ReportingScreen />} />
				<Route path="/diagnosticTool" element={<DiagnosticToolScren />} />
				<Route path="/partner" element={<PartnerScreen />} />
				{/* <Route path="/pricing" element={<PricingScreen />} /> */}
				<Route path="/faqs" element={<FAQScreen />} />
				{/* <Route path="/contact-us" element={<ContactUsScreen />} /> */}
				<Route path="*" element={<PageNotFoundScreen />} />
				<Route path="/payment-failed" element={<PaymentFailed />} />
				<Route path="/confirm-shipping" element={<ConfirmShip />} />
				<Route path="/get-started" element={<GetStarted />} />
				<Route path="/register" element={<ITRegister />} />
				<Route path="/refund-policy" element={<RefundPolicy />} />
				<Route path="/login" element={<ITLogin />} />
				<Route path="/about-us" element={<AboutUsScreen />} />
				<Route path="/idfc-connect" element={<IdfcConnect />} />
				<Route path="/register-assetze" element={<RegisterScreenNew />} />
				<Route path="/register-init" element={<RegisterScreen />} />
				<Route path="/download-agent" element={<DownloadAgent />} />
				{/* <Route path="/survey" element={<Survey />} /> */}
			</Routes>
		</>
	);
}

export default App;
